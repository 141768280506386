/*
 * @Author: gzy 810461372@qq.com
 * @Date: 2023-11-17 10:47:37
 * @LastEditors: yangyongtao
 * @LastEditTime: 2024-01-30 15:17:42
 * @FilePath: \web-gaea_new\public\GaeaMethods.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Description:
 * @Version: 1.0
 * @Author: yyt17671106770
 * @Date: 2023-10-15 17:52:54
 * @LastEditors: gzy 810461372@qq.com
 * @LastEditTime: 2023-11-17 16:20:30
 */

import { center } from "@turf/turf";
import Gaea from "./index.js";
import { vue } from "vue";

import pinia from "@/store/store.js";
import Tween from "@tweenjs/tween.js";
import { storeToRefs } from "pinia";
import gaeaData from "@/store/module/gaeaData.js";

const gaeaExample = gaeaData(pinia);
const { rollerPower, gltfList } = storeToRefs(gaeaExample);

Date.prototype.Format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "H+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

/**
 * 获取路径中的文件名称
 * @param {string} url
 */
function getFlieName(url) {
  // url=url.replace(/\%23/g,"#")
  let index = url.lastIndexOf("/");
  let flieName = url.substring(index + 1, url.length);
  return flieName;
}

/**
 * @description: 加载文件
 * @param {*} url 加载路径
 * @return {*}
 */
function LoadResourceAsync(url = "") {
  let name = url.substring(url.lastIndexOf("/") + 1);
  return new Promise((res, rej) => {
    Gaea.GaeaResourceLoader.Instance.LoadResourceFromUrl1(
      url,
      name,
      (code, msg) => {
        if (code == 200) {
          res();
        } else {
          rej(new Error("出错啦!!!!!"));
        }
      }
    );
  });
}

//生成uuid
/**
 * @param {number} len 生成长度
 * @param {number} radix 进制
 * @return {string}
 */
function uuid(len = 8, radix = 16) {
  var chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  var uuid = [],
    i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join("");
}

function base64FileHeaderMapper(fileBase64) {
  let fileHeader = new Map();

  //获取不同文件的文件头前3个字作为判断依据
  fileHeader.set("/9j", "JPG");
  fileHeader.set("iVB", "PNG");
  fileHeader.set("Qk0", "BMP");
  fileHeader.set("SUk", "TIFF");
  fileHeader.set("JVB", "PDF");
  fileHeader.set("UEs", "OFD");

  let res = "";

  //遍历map中所提及的文件头特征
  fileHeader.forEach((v, k) => {
    if (k == fileBase64.substr(0, 3)) {
      res = v;
    }
  });

  //如果不在map中返回unknown file
  if (res == "") {
    res = "unknown file";
  }

  //否则返回map中的value值
  return res;
}

// 防抖时间
let debounceTimeout = null;

/**
 * 防抖函数
 * @param {string} url
 */
function debounce(func, wait, immediate) {
  let context = this;
  let args = arguments;

  if (debounceTimeout) clearTimeout(debounceTimeout); // debounceTimeout 不为null
  if (immediate) {
    let callNow = !debounceTimeout; // 第一次会立即执行，以后只有事件执行后才会再次触发
    debounceTimeout = setTimeout(function () {
      debounceTimeout = null;
    }, wait);
    if (callNow) {
      func.apply(context, args);
    }
  } else {
    debounceTimeout = setTimeout(function () {
      func.apply(context, args);
    }, wait);
  }
}

export default class App {
  constructor() {
    //获取到的服务列表
    this.layers = [];
    //影像图层
    this.tLayers = [];
    //鼠标点击事件
    this.mouserUp = null;
    //鼠标滚动事件
    this.mouserWheel = null;
    //鼠标移动事件
    this.mouseMove = null;

    // 地形数组
    this.dxyb_Arr = [];
    // 地形演变定时器
    this.dxyb_Interval = null;

    // 画线
    this.importBox = false;

    // 流场对象
    this._streaml = null;

    this.tdtile = null;
  }

  // //获取静态资源路径
  getServerAssets = async () => {
    // 配置文件
    var cfg_code2 = `[Engine]
        DefaultGlobalOverview="Global2.jpg"
        AssetHost="${getServerAssets}"`;
    console.log(getServerAssets, "getServerAssets");
    Gaea.EngineConfig.Instance.LoadConfig(cfg_code2);
    Gaea.World.Instance.LoadDefaultEngineConfig();
    await this.InitFontTexture();

    // 鼠标操作反向
    Gaea.World.Instance.ReverseOperation = true;

    // 开启选择
    Gaea.QueryServer.Instance.Enable = true;

    // 设置相机高度上限
    Gaea.World.Instance.DefaultCamera.MaximumAltitudeForSphere = 10000000;

    // 滚轮力度
    Gaea.World.Instance.DefaultCamera.CameraZoomAcceleration = 2.5;

    // // debug模式
    // Gaea.World.Instance.DebugCoroutineManager = false;

    // Gaea.World.Instance.CoroutineManager.ParallelCount =
    //   Gaea.World.Instance.CoroutineManager.ParallelCount * 2;

    // Gaea.GaeaWorkerManager.Instance.MaxIOReadCount =
    //   Gaea.GaeaWorkerManager.Instance.MaxIOReadCount * 2;

    // Gaea.GaeaWorkerManager.Instance.MaxRequestCount = 18;

    // Gaea.GaeaWorkerManager.Instance.MaxIOWriteCount =
    //   Gaea.GaeaWorkerManager.Instance.MaxIOWriteCount * 2;

    // 相机动画时间比例 1~0
    Gaea.WorldCamera.ConvertBy(
      Gaea.World.Instance.DefaultCamera
    ).SlerpPercent = 0.1;

    await this.loadImageResource();

    this.setSkyColor();
  };

  loadImageResource = async () => {
    await this.LoadResourceFromUrl(
      `${window.location.origin}/static/billBoardImage/标记发光底图.png`,
      "标记发光底图.png"
    );
    await this.LoadResourceFromUrl(
      `${window.location.origin}/static/billBoardImage/室外发光底图.png`,
      "室外发光底图.png"
    );
  };

  setSkyColor = async () => {
    var s = Gaea.World.Instance.GetNode("Sky");
    var sky = Gaea.MeshInstance.ConvertBy(s);

    let backgroundName = `SkyDay.png`;

    await this.LoadResourceFromUrl(
      `${window.location.origin}/static/SkyDay.png`,
      backgroundName
    );

    var mtl3 = new Gaea.SpatialMaterial();
    // 加载图片数据
    const resourceInstanceID =
      Gaea.GaeaResourceLoader.Instance.GetResource(backgroundName);
    const texture = new Gaea.Texture(false);
    texture.InstanceID = resourceInstanceID.toString();
    console.log("云层图片", resourceInstanceID);
    console.log("云层图片", texture);
    mtl3.AlbedoTexture = texture;
    // mtl3.Uv1Scale = new Gaea.Vector3(5, 5, 5);

    // mtl3.AlbedoColor = new Gaea.Color(136 / 255, 191 / 255, 184 / 255, 0.5);
    mtl3.ParamsCullMode = 2;

    sky.SetSurfaceMaterial(0, mtl3);
  };

  //获取服务列表
  getAllServe = async () => {
    var result = await (
      await fetch(`${serveUrl}htc/service/wmts?REQUEST=GetCapabilities`)
    ).text();
    let hostUrl = `${serveUrl}htc/service/wmts`;
    const layers = Gaea.WMTSImageStore.ParserXml2Capabilities(hostUrl, result);
    this.layers = [...this.layers, ...layers];
  };
  /***
   * 添加图层
   * @param {String} layerName 影像图层名称
   */
  addLayer = (layerObj, callback = undefined) => {
    const { layerName, max, min } = layerObj;
    // Gaea.World.Instance.UseEscapeUri = false;
    var ts_ibo_c;
    var tileset = Gaea.World.Instance.DefaultPyramidTileSet;
    if (this.layers == null) return;
    //创建图层对象
    ts_ibo_c = new Gaea.WMTSImageStore();
    ts_ibo_c.ImageExtension = Gaea.ImageExtensionEnum.PNG;
    //拿出图层对象
    var zcq = this.layers.filter((i) => i.Title == layerName)[0];
    ts_ibo_c.CurrentCapabilitie = zcq;
    if (max) {
      ts_ibo_c.LevelRange = new Gaea.Vector2(min, max);
    }
    //如果存在该对象，instanceID为空或者0
    if (!ts_ibo_c.CurrentCapabilitie.InstanceID) {
      console.error(`${layerName}图层加载异常`);
    } else {
      tileset.AddImageStore(ts_ibo_c);
      return ts_ibo_c;
    }
  };

  addTDTLayer = () => {
    var store = new Gaea.TDTWMTSImageStore();

    store.ServerUri = "http://t{0}.tianditu.gov.cn/img_c/wmts";

    store.FormatString =
      "SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=c&FORMAT=tiles&TILECOL={0}&TILEROW={1}&TILEMATRIX={2}&tk={3}";

    store.ImageExtension = Gaea.ImageExtensionEnum.JPEG;

    store.TK = "74f8b55404cdde2e55c827e45605dbc1";

    store.Name = "影像底图";

    Gaea.World.Instance.DefaultPyramidTileSet.AddImageStore(store);
  };

  /***
   * 移除图层
   * @param {String} layerName 影像图层名称
   */
  removeLayer = (layerName) => {
    if (layerName == null) return;
    //拿出图层对象
    var layer = this.tLayers.filter(
      (i) => i.CurrentCapabilitie.Title == layerName
    );
    if (layer[0]) {
      // 移除服务
      var tileset = Gaea.World.Instance.DefaultPyramidTileSet;
      tileset.RemoveImageStore(layer[0]);
      this.tLayers = this.tLayers.filter((item) => {
        (item) => item.CurrentCapabilitie.Title !== layerName;
      });
    }
  };
  /***
   * 添加点、线、面
   * @param {String} featureObj.type 类型
   * @param {String} featureObj.feature 数据
   * @param {String} featureObj.color 颜色
   * @param {Number} featureObj.height 标注的高度
   * @param {Number} size type为点时需要传输
   * @return {Number} 标注的id
   */
  addFeature = (featureObj) => {
    const { type, feature, color, height } = featureObj;
    const size = featureObj.size || 10;
    //配置颜色
    const colors = {
      Green: Gaea.Colors.Green,
      Red: Gaea.Colors.Red,
      HotPink: Gaea.Colors.HotPink,
      BlueViolet: Gaea.Colors.BlueViolet,
      DimGray: Gaea.Colors.DimGray,
      Blue: Gaea.Colors.Blue,
    };
    //周长
    const radiusHeight = Gaea.World.Instance.WorldRadius;

    const elv = height + radiusHeight;
    //类型

    var featureId = null;
    let featureArr = JSON.parse(JSON.stringify(feature));
    switch (type) {
      //画线
      case "line":
        featureArr.unshift(featureArr[0]);
        const lineList = [];
        for (let index = 0; index < featureArr.length; index++) {
          const element = featureArr[index];
          console.table(element);
          let p = new Gaea.Vector3(element[1], element[0], elv);
          var spacepos = Gaea.GaeaMath.SphericalToCartesianDeg(p);
          lineList.push(spacepos);
        }
        featureId = Gaea.DebugDraw.PostDrawLine(lineList, colors[color]);
        return featureId;
        break;
      //画面
      case "polygon":
        const polygonList = [];
        for (let index = 0; index < featureArr.length; index++) {
          const element = featureArr[index];
          console.table(element);
          let p = new Gaea.Vector3(element[1], element[0], elv);
          var spacepos = Gaea.GaeaMath.SphericalToCartesianDeg(p);

          polygonList.push(spacepos);
        }

        // if (type == 'polygon') {
        //     const polygon = feature.map(element => {
        //         console.table(element)
        //         let p = new Gaea.Vector3(element[1], element[0], elv)

        //         var spacepos = Gaea.GaeaMath.SphericalToCartesianDeg(p)
        //         return spacepos
        //     })

        //     const id = Gaea.DebugDraw.PostDrawPolygon(polygon, colors[color])

        //     return id
        // }
        featureId = Gaea.DebugDraw.PostDrawPolygon(polygonList, colors[color]);
        return featureId;
        break;
      // 画点
      case "point":
        let element = featureArr;
        var p = new Gaea.Vector3(element[1], element[0], elv);
        var spacepos = Gaea.GaeaMath.SphericalToCartesianDeg(p);
        featureId = Gaea.DebugDraw.PostDrawPoint(spacepos, size, colors[color]);
        return featureId;
        break;
    }
  };
  /***
   * 移除点、线、面标注
   * @param {Number} featureId 标注id
   * @return {Number} 标注的id
   */
  removeFeature = (featureId) => {
    Gaea.DebugDraw.RemoveElement(featureId);
    return featureId;
  };

  //移除公关牌
  RemoveBillBoard = (billBoardID) => {
    Gaea.World.Instance.RemoveChild(billBoardID);
  };

  /***
   * 移除图片标注
   * @param {Number} featureId 标注id
   * @return {Number} 标注的id
   */
  removeImage = (imageId) => {
    Gaea.DecalManager.Instance.RemoveItem(imageId);
    return imageId;
  };

  /**
   * 定位
   * @param {*} y
   * @param {*} x
   * @return {void}
   */
  SetPosition = (y = 30, x = 115) => {
    var camera = Gaea.World.Instance.DefaultCamera;
    camera.SetPosition(y, x);
  };
  /**
   * 高级定位
   **/
  SetPosition2 = (pos, lon, heading, alt, tilt, bank) => {
    const camera = Gaea.WorldCamera.ConvertBy(
      Gaea.World.Instance.DefaultCamera
    );
    camera.SlerpPercent = 1.0;
    camera.SetPosition2(pos, lon, heading, alt, tilt, bank);
  };
  //记录当前坐标
  favorite = (cb) => {
    var uri = Gaea.World.Instance.DefaultCamera.Favorite();
    cb(uri);
  };

  /**
   * @description:定位到uri位置
   * @param {*} uri 坐标点
   * @return {*}
   */
  favoritePosition = (uri) => {
    const camera = Gaea.WorldCamera.ConvertBy(
      Gaea.World.Instance.DefaultCamera
    );
    camera.SlerpPercent = 0.1;
    camera.GotoFavorite(uri);
  };

  //同步加载地形
  promiseTerrain = (url) => {
    return new Promise((resolve, reject) => {
      Gaea.World.Instance.InitTerrainServer2(url, () => {
        resolve();
      });
    });
  };

  /**
   * 加载地形
   * @param {String} url
   * @return {void}
   */
  addInitTerrainServer = () => {
    return new Promise(async (res, rej) => {
      for (const key in TerrainList) {
        await this.promiseTerrain(TerrainList[key]);
      }

      res();
    });
  };
  //监听鼠标点击事件
  addMouseUp = (cb = null) => {
    this.mouserUp = Gaea.World.Instance.OnMouseUp((p, b) => {
      if (b == 1) {
        var camera = Gaea.World.Instance.DefaultCamera;
        var screenpos = new Gaea.Vector2(p.x, p.y);
        var spacepos = Gaea.GaeaMath.ProjectPosition(screenpos);
        var latlonalt = Gaea.GaeaMath.CartesianToSphericalDeg(spacepos);
        var spacepos2 = Gaea.GaeaMath.SphericalToCartesianDeg(latlonalt);
        var screenpos2 = Gaea.GaeaMath.UnProjectPosition(spacepos2);

        //var ele = new Gaea.GLTFElement()
        //ele.GeographyPosition = latlonalt
        //ele.Root.Translation = spacepos
        //屏幕坐标
        // console.log("屏幕坐标--------", screenpos.instance);
        // 屏幕空间转笛卡尔坐标
        console.log("屏幕空间转笛卡尔坐标------", spacepos.instance);
        // 经纬度坐标
        console.log("经纬度坐标------", latlonalt.instance);
        //还原笛卡尔坐标
        // console.log("还原笛卡尔坐标------", spacepos2.instance);
        //还原为屏幕坐标
        // console.log("还原为屏幕坐标------", screenpos2.instance);
        // 鼠标高度
        console.log("鼠标高度------", camera.Altitude);
        // cb(latlonalt.instance);
      }
    });
  };
  //移除鼠标点击事件
  removeMouseUp = () => {
    if (this.mouserUp) {
      Gaea.World.Instance.ClearEvent(this.mouserUp);
      this.mouserUp = null;
    }
  };
  //监听滚动事件
  addMouseWhill = (cb = null) => {
    this.mouserWheel = Gaea.World.Instance.OnMouseWheel((p, b) => {
      if (p == 1 || p == -1) {
        const { Altitude } = Gaea.World.Instance.DefaultCamera;

        debounce(
          () => {
            console.log(Altitude);
            if (Altitude < 60) {
              Gaea.World.Instance.DefaultCamera.CameraZoomAcceleration = 0.01;
            } else {
              Gaea.World.Instance.DefaultCamera.CameraZoomAcceleration = 2.5;
            }
            console.log(
              Gaea.World.Instance.DefaultCamera.CameraZoomAcceleration
            );
            rollerPower.value =
              Gaea.World.Instance.DefaultCamera.CameraZoomAcceleration;
            // Gaea.World.Instance.DefaultCamera.CameraZoomAcceleration = 2.5;
          },
          0.5 * 1000,
          false
        );
      }
    });
  };
  //移除监听滚动事件
  removeMouserWheel = () => {
    if (this.mouserWheel) {
      Gaea.World.Instance.ClearEvent(this.mouserWheel);
      this.mouserWheel = null;
    }
  };
  //添加鼠标移动事件
  addMouseMove = (cb = null) => {
    this.mouseMove = Gaea.World.Instance.OnMouseMove((p, b) => {
      if (b == 1) {
        var screenpos = new Gaea.Vector2(p.x, p.y);
        var spacepos = Gaea.GaeaMath.ProjectPosition(screenpos);
        var latlonalt = Gaea.GaeaMath.CartesianToSphericalDeg(spacepos);
        var spacepos2 = Gaea.GaeaMath.SphericalToCartesianDeg(latlonalt);
        var screenpos2 = Gaea.GaeaMath.UnProjectPosition(spacepos2);

        //屏幕坐标
        // console.log(screenpos.instance);
        //屏幕空间转笛卡尔坐标
        // console.log(spacepos.instance);
        //经纬度坐标
        // console.log(latlonalt.instance);
        //还原笛卡尔坐标
        // console.log(spacepos2.instance);
        //还原为屏幕坐标
        // console.log(screenpos2.instance);
        // Gaea.World.Instance.ClearEvent(move)
        // Gaea.World.Instance.ClearEvent(up)

        cb(latlonalt.instance, screenpos.instance);
        // this.point = latlonalt.instance
      }
    });
  };
  //移除鼠标移动事件
  removeMouseMove = () => {
    if (this.mouseMove) {
      Gaea.World.Instance.ClearEvent(this.mouseMove);
      this.mouseMove = null;
    }
  };

  updateElementPosition = ({ element, position, rotate, size }) => {
    element.GeographyPosition = new Gaea.Vector3(
      position.x,
      position.y,
      position.z
    );

    if (rotate.length > 0) {
      element.TiltHeadingBank = new Gaea.Vector3(
        rotate[2],
        rotate[1],
        rotate[0]
      );
    }

    // element.Root.Translation = new Gaea.Vector3(
    //   position.x,
    //   position.y,
    //   position.z
    // );

    // element.Scale = new Gaea.Vector3(size[0], size[1], size[2]);
  };

  /**
   * @description: 视角锁定模式切换
   * @param {Boolean} mode 锁定模式是否开启
   * @param {Object} element 需要锁定的模型
   * @param {Boolean} EyeLock 是否允许视角拖拽
   * @param {Boolean} lockDistance 视角距离是否锁定
   * @param {Number} distance 相机到模型的距离
   * @param {Array} rotate 模型的旋转角度
   * @param {Array} offset 模型的相对本身偏移
   * @return {*}
   */
  lockedModesSwitch = (
    mode,
    element,
    EyeLock,
    lockDistance,
    distance = [],
    rotate = [],
    offset = []
  ) => {
    const camera = Gaea.WorldCamera.ConvertBy(
      Gaea.World.Instance.DefaultCamera
    );
    camera.SlerpPercent = 0.1; // 0.1 有飞行动画 1.0 无飞行动画
    if (mode) {
      // 设置要锁定的空间实体
      if (element.Root) {
        camera.LockedEntity = element.Root;
      } else {
        camera.LockedEntity = element;
      }
      // camera.LockedEntity.Visible = false;

      if (rotate.length) {
        camera.LockToEntityTiltHeadingBankOffset = new Gaea.Vector3(
          rotate[0],
          rotate[1],
          rotate[2]
        );
      }

      if (element.Root) {
        element.Root.Translation = new Gaea.Vector3(0, 0, 0);
      } else {
        element.Translation = new Gaea.Vector3(0, 0, 0);
      }

      if (offset.length) {
        element.Root.TranslateObjectLocal(
          new Gaea.Vector3(offset[0], offset[1], offset[2])
        );
      }

      // 设置视图方向
      // camera.ViewModes = Gaea.ViewModes.Back;

      // 控制锁定后相机是否可拖拽
      camera.EyeLock = EyeLock;

      // 是否需要动画 false为需要动画
      // camera.LockedNoSlerp = true;

      // 设置相机到该空间实体的距离
      // camera.TargetDistance = distance;
      //  锁定视角距离
      if (lockDistance) {
        if (distance.length) {
          camera.LockToEntityDistanceMax = distance[1];
          camera.LockToEntityDistanceMin = distance[0];
        } else {
          camera.LockToEntityDistanceMax = distance;
          camera.LockToEntityDistanceMin = distance;
        }
      } else {
        if (distance.length) {
          camera.LockToEntityDistanceMax = distance[1];
          camera.LockToEntityDistanceMin = distance[0];
        } else {
          camera.LockToEntityDistanceMax = 10;
          camera.LockToEntityDistanceMin = distance;
        }
      }

      // camera.OnFlyOver = () => {
      //   camera.LockedEntity = element.Root;
      //   element.Root.TranslateObjectLocal(new Gaea.Vector3(-540, 70, 30));
      //   camera.LockToEntityDistanceMax = 0.4;
      //   camera.LockToEntityDistanceMin = 0.4;
      //   camera.LockToEntityTiltHeadingBankOffset = new Gaea.Vector3(90, -90, 0);
      //   camera.OnFlyOver = null;
      // };
      // 锁定距离
    } else {
      camera.LockedEntity = null;
    }
  };

  FlyOver = () => {
    const camera = Gaea.World.Instance.DefaultCamera;
    return new Promise((res, rej) => {
      camera.OnFlyOver = () => {
        camera.OnFlyOver = null;
        res();
      };
    });
  };

  Unlocked = (pos, lon, heading, alt, tilt, bank) => {
    const camera = Gaea.World.Instance.DefaultCamera;
    camera.Unlocked(pos, lon, heading, alt, tilt, bank);
  };

  addGuiSymbol = ({
    name,
    position,
    size,
    visibleRange,
    bgColor = [],
    label,
    paramsBillboardMode,
  }) => {
    const guiSymbol = new Gaea.GuiSymbol();
    guiSymbol.name = name;

    guiSymbol._Init();

    // 设置坐标
    guiSymbol.Root.Translation = Gaea.GaeaMath.SphericalToCartesianDeg(
      new Gaea.Vector3(
        position.x,
        position.y,
        Gaea.World.Instance.WorldRadius + position.z
      )
    );

    // 设置缩放
    guiSymbol.Root.Scale = new Gaea.Vector3(size[0], size[1], size[2]);
    // var  guiSymbol.Root.GetNode("Viewport")
    // 设置文字大小（固定写法
    guiSymbol.SetTextSize(new Gaea.Vector2(64 * 7, 64 * 1.5));

    // 设置标记面片大小（固定写法
    var quadmesh = Gaea.QuadMesh.ConvertBy(guiSymbol.GuiQuad.Mesh);
    quadmesh.SetSize(new Gaea.Vector2(4.66, 1));

    // 缩放（固定写法
    guiSymbol.GuiQuad.Scale = new Gaea.Vector3(0.04, 0.04, 0.04);

    Gaea.World.Instance.AddChild(guiSymbol.Root, false);

    // 添加盒子
    var box = new Gaea.StyleBoxFlat();
    if (bgColor.length) {
      box.BgColor = new Gaea.Color(
        bgColor[0] / 255,
        bgColor[1] / 255,
        bgColor[2] / 255,
        bgColor[3]
      );
      console.log(box.BgColor);
    } else {
      box.BgColor = new Gaea.Color(20 / 255, 65 / 255, 137 / 255, 1);
    }
    guiSymbol.GuiPanel.AddStyleboxOverride("panel", box);

    const centerContainer = new Gaea.CenterContainer();
    const VBoxContainer = new Gaea.VBoxContainer();

    // 设置样式
    centerContainer.AnchorRight = 1;
    centerContainer.AnchorBottom = 1;

    guiSymbol.GuiPanel.AddChild(centerContainer, false);
    centerContainer.AddChild(VBoxContainer, false);

    Object.values(label).forEach((item) => {
      // 添加标记
      const label = new Gaea.Label();
      VBoxContainer.AddChild(label, false);
      label.Text = item;
    });

    var mtl = Gaea.SpatialMaterial.ConvertBy(
      guiSymbol.GuiQuad.GetSurfaceMaterial(0)
    );
    mtl.ParamsBillboardMode = paramsBillboardMode;

    if (paramsBillboardMode === 1) {
      mtl.FlagsFixedSize = true;

      mtl.ParamsBillboardKeepScale = true;
    }

    mtl.RenderPriority = 99;
    mtl.FlagsTransparent = true;
    // mtl.AlbedoColor = new Gaea.Color(1, 1, 1, 0.5);

    // 设置显示层级
    mtl.DistanceFadeMode = 1;
    mtl.DistanceFadeMinDistance = visibleRange[0];
    mtl.DistanceFadeMaxDistance = visibleRange[1];
    guiSymbol.GuiQuad.SetSurfaceMaterial(0, mtl);

    return guiSymbol;
  };

  updateGuiSymbolText = (guiSymbol, text) => {
    const label = Gaea.Label.ConvertBy(guiSymbol);

    label.Text = text;
  };

  /**
   * @description: gltf模型旋转动画
   * @param {Array} trackList 动画轨迹坐标
   * @param {Object} animationConfig 动画配置
   * @param {String} animationConfig.animationName 动画名称
   * @param {String} animationConfig.animationType 动画类型(时间 time & 速度 speed)
   * @param {Number} animationConfig.animationPlaySpeed 动画时长/动画速度
   * @param {Boolean} animationConfig.animationLoop 动画循环
   * @param {Object} element 设置动画的gltf对象
   * @return {*}
   */
  rotAnimation = (element, floor, rotation) => {
    const doorMesh = element.Root.GetChild(floor),
      meshInstance = new Gaea.MeshInstance();

    meshInstance.InstanceID = doorMesh.InstanceID;

    console.log(meshInstance);
    meshInstance.Rotation = new Gaea.Vector3(
      rotation.x,
      rotation.y,
      rotation.z
    );

    return;
    let trackname = "",
      animationName = "";
    const animation = new Gaea.Animation();
    //set transform
    //animation.AddTrack(1,-1)
    //new Gaea.MeshInstance().GetGlobalTransform()
    // animation.TransformTrackInsertKey(0,0,Gaea.Vector3.Zero,,Gaea.Vector3.One);

    animation.TrackSetPath(0, `/root/World2/${trackname}`);
    const animationPlayer = new Gaea.AnimationPlayer();
    animationPlayer.AddAnimation(animationName, animation);
    Gaea.World.Instance.AddChild(animationPlayer, false);
    Gaea.World.Instance.PlayAnimation(animationPlayer, animationName);
  };

  /**
   * @description: gltf模型轨迹动画
   * @param {Array} trackList 动画轨迹坐标
   * @param {Object} animationConfig 动画配置
   * @param {String} animationConfig.animationName 动画名称
   * @param {String} animationConfig.animationType 动画类型(时间 time & 速度 speed)
   * @param {Number} animationConfig.animationPlaySpeed 动画时长/动画速度
   * @param {Boolean} animationConfig.animationLoop 动画循环
   * @param {Object} element 设置动画的gltf对象
   * @return {*}
   */
  gtlfAnimationPlayer = (trackList, animationConfig, element) => {
    // 更新当前模型位置坐标
    element.GeographyPosition = new Gaea.Vector3(
      trackList[0].x,
      trackList[0].y,
      trackList[0].z
    );
    let animationPlayer;
    // 设置动画参数
    const { animationName, animationType, animationPlaySpeed, animationLoop } =
      animationConfig;

    // 创建动画轨迹
    const line = trackList.map(
      (item) => new Gaea.Vector3(item.x, item.y, item.z)
    );
    let bezierCurve = new Gaea.UniformCubicSpline();
    for (let i = 0; i < line.length; i++) {
      let control = Gaea.GaeaMath.SphericalToCartesianDeg1(
        line[i].x,
        line[i].y,
        Gaea.World.Instance.WorldRadius + line[i].z
      );
      bezierCurve.AddValue(i, control);
    }
    // 插值动画配置
    bezierCurve.Step = 20;
    bezierCurve.Interpolate(true);
    bezierCurve.CalculateLengthAfterInterpolation();

    let animation;

    // 动画以速度播放还是时间播放
    if (animationType === "speed") {
      animation = bezierCurve.ToAnimationBySpeed(
        animationName,
        animationPlaySpeed,
        element.Scale
      );
    } else if (animationType === "time") {
      animation = bezierCurve.ToAnimationByTime(
        animationName,
        true,
        animationPlaySpeed,
        element.Scale
      );
    }

    // 设置动画是否循环
    // if (animationLoop) {
    //   animation.Loop = true;
    // }
    // console.log(element.Root.GetPath());
    animation.TrackSetPath(0, element.GetPath());
    animationPlayer = new Gaea.AnimationPlayer();
    animationPlayer.AddAnimation(animationName, animation);
    element.AddChild(animationPlayer, false);

    // if (true) {

    // } else {
    //   animationPlayer = Gaea.AnimationPlayer.ConvertBy(element.GetChild(1));
    // }
    Gaea.World.Instance.PlayAnimation(animationPlayer, animationName);
    return animationPlayer;
  };

  markAnimationPlayer = (element) => {
    let animationPlayer;
    if (element.GetChildCount() == 1) {
      animationPlayer = new Gaea.AnimationPlayer();
      // var child = Gaea.MeshInstance.ConvertBy(element.GetChild(0)),
      //   material = Gaea.SpatialMaterial.ConvertBy(child.GetActiveMaterial(0));

      // this.setBillboardElementEmission(material, {
      //   color: [255, 0, 0, 1],
      //   emissionEnergy: 10,
      //   emissionOperator: 0,
      // });

      var animation = new Gaea.Animation();
      animation.AddTrack(1, 0);
      animation.TrackSetPath(0, element.GetNodePath());
      // console.log(element.Root);
      // console.log(child);
      animation.TransformTrackInsertKey(
        0,
        0,
        Gaea.Vector3.Zero,
        Gaea.Quat.Identity,
        Gaea.Vector3.One
      );
      animation.TransformTrackInsertKey(
        0,
        1,
        Gaea.Vector3.Zero,
        Gaea.Quat.Identity,
        new Gaea.Vector3(2, 2, 2)
      );
      animation.TransformTrackInsertKey(
        0,
        2,
        Gaea.Vector3.Zero,
        Gaea.Quat.Identity,
        Gaea.Vector3.One
      );

      animation.Loop = true;
      animationPlayer.AddAnimation("UpDownAndScale", animation);
      element.AddChild(animationPlayer, false);
    } else {
      animationPlayer = Gaea.AnimationPlayer.ConvertBy(element.GetChild(1));
    }
    Gaea.World.Instance.PlayAnimation(animationPlayer, "UpDownAndScale");
  };

  /**
   * 新增面片方法
   * @param {String} show 面片是否展示
   * @param {String} base64url 面片材质
   * @param {String} position 位置
   * @param {String} size 大小
   * @param {String} rotate 旋转
   * @param {String} name 面片名称
   * @param {String} paramsBillboardMode 面片展示模式(0,1,2,3)
   * @param {String} backgroundImgUrl 面片背景图
   * @param {Boolean} needScale 是否需要进行缩放
   * @param {Array<Number>?} visibleRange 显示范围
   * @param {String} clickCallBack 面片点击事件
   */
  addBillBoard = async ({
    position,
    size,
    rotate,
    paramsBillboardMode = 0,
    flagsNoDepthTest = false,
    name,
    show,
    childBillboard,
    materialData,
    flagsUnshaded = true,
    emissionConfig = [],
    visibleRange = null,
    needScale = false,
    useAbsoluteHeight = true,
    clickCallBack = () => { },
  }) => {
    // 所有材质集合
    let materialArr = [];

    for (let i = 0; i < materialData.length; i++) {
      const item = materialData[i];
      let resourceInstanceID = "";

      // 加载图片
      if (item.imageType === "png") {
        let backgroundName = `${uuid() + item.imageUrl}`;

        await this.LoadResourceFromUrl(
          `${window.location.origin}/static/billBoardImage/${item.imageUrl}`,
          backgroundName
        );

        // 加载图片数据
        resourceInstanceID =
          Gaea.GaeaResourceLoader.Instance.GetResource(backgroundName);
      } else if (item.imageType === "base64") {
        // 获取base64数据
        let base64 = item.base64;

        // 加载base64数据
        resourceInstanceID =
          await Gaea.GaeaResourceLoader.Instance.LoadResourceFromBase64(
            base64.replace(/^data:image\/\w+;base64,/, ""),
            uuid() + ".png"
          );
      }

      const texture = new Gaea.ImageTexture(false),
        material = new Gaea.SpatialMaterial();

      texture.InstanceID = resourceInstanceID.toString();
      texture.SetFlags(7);

      // 关闭光线对面片的影响
      material.FlagsDisableAmbientLight = material.FlagsUnshaded =
        flagsUnshaded;

      // material.FlagsUnshaded = true;
      // material.FlagsDisableAmbientLight = true;

      // 开启srgb颜色空间转换
      material.FlagsAlbedoTexForceSrgb = true;

      // 添加底图
      material.AlbedoTexture = texture;

      // 底色透明
      material.FlagsTransparent = true;

      // 面片旋转是否可见
      material.ParamsBillboardMode = paramsBillboardMode;

      // 面片旋转后保持缩放
      material.ParamsBillboardKeepScale =
        paramsBillboardMode != 0 ? true : false;

      // 层级设置
      material.RenderPriority = item.zIndex || 99;

      // 设置下一层
      if (materialArr.length != 0) {
        materialArr[materialArr.length - 1].NextPass = material;
      }

      materialArr.push(material);

      // 设置面片缩放
      if (needScale) {
        material.FlagsFixedSize = true;
        material.ParamsBillboardKeepScale = true;
      }

      // 设置多少高度可见
      if (visibleRange) {
        material.DistanceFadeMode = 1;
        material.DistanceFadeMinDistance = visibleRange[0];
        material.DistanceFadeMaxDistance = visibleRange[1];
      }

      if (emissionConfig && emissionConfig.length != 0) {
        emissionConfig.forEach((item) => {
          this.setBillboardElementEmission(material, item);
        });
      }
    }
    let quadmesh = new Gaea.ModelElement();

    // 是否使用相对定位
    quadmesh.UseAbsoluteHeight = useAbsoluteHeight;

    quadmesh.Mesh = new Gaea.QuadMesh();

    // 面片名称
    quadmesh.Name = name;

    // 模型点击
    quadmesh.EnablePick = true;
    // 加面片
    quadmesh.Mesh.SurfaceSetMaterial(0, materialArr[0]);

    // 面片点击触发事件
    quadmesh.OnHitElement((pos) => {
      console.log(name);

      // 点击回调函数
      clickCallBack();
    });

    quadmesh.OnModelInit(() => {
      // 面片位置
      quadmesh.GeographyPosition = new Gaea.Vector3(
        position.x,
        position.y,
        position.z
      );

      // 面片旋转角度
      quadmesh.TiltHeadingBank = new Gaea.Vector3(
        rotate[2],
        rotate[1],
        rotate[0]
      );

      // 面片大小控制
      quadmesh.Scale = new Gaea.Vector3(size[0], size[1], size[2]);

      if (childBillboard && childBillboard.length) {
        childBillboard.forEach((item) => {
          this.test(quadmesh, item);
        });
      }
    });

    // 设置当前面片是否默认展示
    quadmesh.Visible = show;
    Gaea.World.Instance.RenderableObjectList.AddLast(quadmesh);
    return quadmesh;
  };

  test = (
    quadmesh,
    {
      position,
      size,
      rotate,
      paramsBillboardMode = 0,
      flagsNoDepthTest = false,
      name,
      show,
      flagsUnshaded = true,
      materialData,
      emissionConfig = [],
      visibleRange = null,
      needScale = false,
      useAbsoluteHeight = false,
      clickCallBack = () => { },
    }
  ) => {
    return new Promise(async (res, rej) => {
      let chilrenModelElement = new Gaea.ModelElement();

      // 是否使用相对定位
      chilrenModelElement.UseAbsoluteHeight = useAbsoluteHeight;

      chilrenModelElement.Mesh = new Gaea.QuadMesh();

      // 面片名称
      chilrenModelElement.Name = name;

      // 面片旋转角度
      chilrenModelElement.TiltHeadingBank = new Gaea.Vector3(
        rotate[0],
        rotate[1],
        rotate[2]
      );

      // 面片大小控制
      chilrenModelElement.Scale = new Gaea.Vector3(size[0], size[1], size[2]);

      // 模型点击
      chilrenModelElement.EnablePick = true;

      // 所有材质集合
      let materialArr = [];

      for (let i = 0; i < materialData.length; i++) {
        const item = materialData[i];
        let resourceInstanceID = "";

        // 加载图片
        if (item.imageType === "png") {
          let backgroundName = `${uuid() + item.imageUrl}`;

          await this.LoadResourceFromUrl(
            `${window.location.origin}/static/billBoardImage/${item.imageUrl}`,
            backgroundName
          );

          // 加载图片数据
          resourceInstanceID =
            Gaea.GaeaResourceLoader.Instance.GetResource(backgroundName);
        } else if (item.imageType === "base64") {
          // 获取base64数据
          let base64 = item.base64;

          // 加载base64数据
          resourceInstanceID =
            await Gaea.GaeaResourceLoader.Instance.LoadResourceFromBase64(
              base64.replace(/^data:image\/\w+;base64,/, ""),
              uuid() + ".png"
            );
        }

        const texture = new Gaea.ImageTexture(false),
          material = new Gaea.SpatialMaterial();

        texture.InstanceID = resourceInstanceID.toString();
        texture.SetFlags(7);

        // 关闭光线对面片的影响
        material.FlagsDisableAmbientLight = material.FlagsUnshaded =
          flagsUnshaded;

        // 开启srgb颜色空间转换
        material.FlagsAlbedoTexForceSrgb = true;

        // 添加底图
        material.AlbedoTexture = texture;

        // 底色透明
        material.FlagsTransparent = true;

        // 面片旋转是否可见
        material.ParamsBillboardMode = paramsBillboardMode;

        // 面片旋转后保持缩放
        material.ParamsBillboardKeepScale =
          paramsBillboardMode != 0 ? true : false;

        // 层级设置
        material.RenderPriority = item.zIndex || 99;

        // 设置下一层
        if (materialArr.length != 0) {
          materialArr[materialArr.length - 1].NextPass = material;
        }

        materialArr.push(material);

        // 设置面片缩放
        if (needScale) {
          material.FlagsFixedSize = true;
          material.ParamsBillboardKeepScale = true;
        }

        // 设置多少高度可见
        if (visibleRange) {
          material.DistanceFadeMode = 1;
          material.DistanceFadeMinDistance = visibleRange[0];
          material.DistanceFadeMaxDistance = visibleRange[1];
        }

        // if (emissionConfig && emissionConfig.length != 0) {
        //   emissionConfig.forEach((item) => {
        //     this.setBillboardElementEmission(material, item);
        //   });
        // }
      }

      // 加面片
      chilrenModelElement.Mesh.SurfaceSetMaterial(0, materialArr[0]);

      // 面片点击触发事件
      chilrenModelElement.OnHitElement((pos) => {
        // 点击回调函数
        clickCallBack();
      });

      quadmesh.AddChild(chilrenModelElement, false);
      chilrenModelElement.OnModelInit(() => {
        // Gaea.World.Instance.RemoveChild(chilrenModelElement.Root);
        chilrenModelElement.Translation = new Gaea.Vector3(
          position.x,
          position.y,
          position.z
        );
      });

      // const ins = Gaea.MeshInstance.ConvertBy(modelElement.Root.GetChild(0));
      // ins.MaterialOverride = materialArr[0];
      // ins.ScaleObjectLocal(new Gaea.Vector3(1000, 1000, 1000));

      // Gaea.World.Instance.RenderableObjectList.AddLast(modelElement);

      // modelElement.Root.TranslateObjectLocal(
      //   new Gaea.Vector3(position.x, position.y, position.z)
      // );

      // 设置当前面片是否默认展示
      chilrenModelElement.Visible = show;
      // Gaea.World.Instance.RenderableObjectList.AddLast(chilrenModelElement);

      res(chilrenModelElement);
    });
  };

  setBillboardElementEmission = async (
    material,
    {
      color = Array,
      emissionEnergy = Number,
      intervalEmission = Boolean,
      emissionOperator = Number,
      imageName = null,
    }
  ) => {
    // 开启发光效果
    material.EmissionEnabled = true;

    if (imageName) {
      const emissionTexture = new Gaea.Texture(false);

      emissionTexture.InstanceID =
        Gaea.GaeaResourceLoader.Instance.GetResource(imageName).toString();

      // 添加底图
      material.EmissionTexture = emissionTexture;
    }

    // 设置光线颜色
    material.Emission = new Gaea.Color(
      color[0] / 255,
      color[1] / 255,
      color[2] / 255,
      color[3]
    );

    // 设置光照能量
    if (intervalEmission) {
      // let number = 0;
      // setInterval(() => {
      //   material.EmissionEnergy = 5 * (Math.sin((number * 6.28) / 360.0) + 1);
      //   number += 36;
      //   number = number % 360;
      // }, 50);
    } else {
      material.EmissionEnergy = emissionEnergy;
    }

    // 颜色模式
    material.EmissionOperator = emissionOperator;
  };

  /**
   * @param {String} name 模型的名称
   * @param {String} url 模型的请求位置
   * @param {Object} position 模型的位置
   * @param {Object} size 模型的大小
   * @param {Boolean} show 是否展示当前模型
   * @param {Boolean} enablePick 当前模型是否触发点击事件
   * @param {Boolean} useAbsoluteHeight 是否使用绝对定位
   * @param {Array} children 主模型下的子模型
   * @param {Array} billboard 主模型下某层的面片
   * @param {Function} clickCallBack 模型点击事件回调
   */
  AddGltf = ({
    name,
    url,
    position,
    rotate,
    size,

    show = true,
    childBillboard,
    enablePick,
    useAbsoluteHeight = true,
    children,
    billboard,
    clickChilrenNameArray,
    emissionConfig = [],
    initFun = () => { },
    clickCallBack = () => { },
    clickChilrenCallBack = () => { },
  }) => {
    return new Promise(async (res, rej) => {
      const element = new Gaea.GLTFElement();

      // 是否使用绝对定位
      element.UseAbsoluteHeight = useAbsoluteHeight;

      // 模型请求地址
      element.ResourceName = url;

      if (name === "壁画细" || name === "水文站右侧" || name === "水文站左侧") {
        element.UseLight = false;
      }

      console.log("模型开始加载：" + name);
      console.log(new Date().Format("yyyy-MM-dd HH:mm:ss"));

      element.OnModelInit(async () => {
        console.log(new Date().Format("yyyy-MM-dd HH:mm:ss"));
        console.log("模型加载完毕：" + name);
        if (name === "壁画细") {
          // isBigGltfProgress.value = false;
        }
        // 初始化方法
        initFun();
        // 模型点击
        element.EnablePick = enablePick;

        // 模型定位
        element.GeographyPosition = new Gaea.Vector3(
          position.x,
          position.y,
          position.z
        );

        // 模型旋转
        element.TiltHeadingBank = new Gaea.Vector3(
          rotate[0],
          rotate[1],
          rotate[2]
        );

        // 模型缩放
        element.Root.Scale = new Gaea.Vector3(size[0], size[1], size[2]);

        // 模型命名
        element.Root.Name = name;

        if (name == "水文站外壳" || name == "地形") {
          var num = element.Root.GetChildCount();
          for (var k = 0; k < num; k++) {
            var child = Gaea.MeshInstance.ConvertBy(element.Root.GetChild(k));
            var n = child.Mesh.GetSurfaceCount();
            for (var j = 0; j < n; j++) {
              if (j < 9) {
                var index = (j + 1).toFixed();
                var path;
                if (name == "水文站外壳") {
                  path =
                    "res://swzjz res/" + child.Name + "-0" + index + ".res";
                } else {
                  path =
                    "res://swzdx res/" + child.Name + "-0" + index + ".res";
                }
                // var path ="res://swzdx res/" + child.Name +"-0" + index +".res";

                var mtlID =
                  Gaea.GaeaResourceLoader.Instance.LoadLoaclResource(path);
                var mtl = Gaea.SpatialMaterial.ConvertBy(mtlID);

                // var mtl = GD.Load < SpatialMaterial > path;
                if (mtlID.InstanceID != null) {
                  child.Mesh.SurfaceSetMaterial(j, mtl);
                } else {
                  console.log("无材质" + path);
                }
              } else {
                var index = (j + 1).toFixed();
                // var path ="res://swzdx res/" + child.Name +"-0" + index +".res";
                if (name == "水文站外壳") {
                  path = "res://swzjz res/" + child.Name + "-" + index + ".res";
                } else {
                  path = "res://swzdx res/" + child.Name + "-" + index + ".res";
                }
                var mtlID =
                  Gaea.GaeaResourceLoader.Instance.LoadLoaclResource(path);
                var mtl = Gaea.SpatialMaterial.ConvertBy(mtlID);

                // var mtl = GD.Load < SpatialMaterial > path;
                if (mtlID.InstanceID != null) {
                  child.Mesh.SurfaceSetMaterial(j, mtl);
                } else {
                  console.log("无材质" + path);
                }
              }
            }
            console.log(child.Name);
          }
        }

        // 在当前模型下添加子模型
        if (children && children.length != 0) {
          let childrenElement = [];
          children.forEach(async (item) => {
            console.log(name);

            switch (item.type) {
              case "gltf":
                childrenElement.push(await this.addChildrenGltf(element, item));
                element.childrenElement = childrenElement;
                break;
              case "Billboard":
                this.addChildrenBillboard(element, item);
                break;
            }
          });
        }

        // 在当前模型下某层添加面片
        if (billboard && billboard.length != 0) {
          billboard.forEach((item) => {
            this.addBillBoardByGltfFloor(element, item);
          });
        }

        // 添加当前模型需要点击的子元素名称
        if (clickChilrenNameArray && clickChilrenNameArray.length != 0) {
          clickChilrenNameArray.forEach((item) => {
            var ins = Gaea.MeshInstance.ConvertBy(
              element.GetMeshInstanceByName(item)
            );

            Gaea.QueryServer.Instance.AddHitMeshInstance(element, ins);
          });
        }

        if (emissionConfig && emissionConfig.length != 0) {
          emissionConfig.forEach((item) => {
            const root = element.Root.GetChild(item.floor);
            const rootInstance = Gaea.MeshInstance.ConvertBy(root),
              activeMaterial = rootInstance.GetActiveMaterial(0),
              spatialMaterial = Gaea.SpatialMaterial.ConvertBy(activeMaterial);
            this.setBillboardElementEmission(spatialMaterial, item);
          });
        }

        if (name.includes("箭头")) {
          this.markAnimationPlayer(element);
        }

        element.OnHitElement((pos) => {
          clickCallBack();
        });

        element.OnHitChildElement((childName, pos) => {
          console.log(childName);
          clickChilrenCallBack(childName);
        });

        if (childBillboard && childBillboard.length) {
          let childBillboardList = [];
          childBillboard.forEach(async (item) => {
            childBillboardList.push(await this.test(element, item));
          });

          element.ChildrenBillboardArr = childBillboardList;
        }
        // 模型是否展示
        element.Root.Visible = show;
        res(element);
      });

      Gaea.World.Instance.RenderableObjectList.AddLast(element);

      setTimeout(() => {
        res(element);
      }, 20 * 1000);
    });
  };

  setRiverGltfElementEmission = (
    elementArr,
    { floor, color, emissionEnergy, emissionOperator, waterLevel }
  ) => {
    const root = elementArr[0].Root.GetChild(floor);

    const rootInstance = Gaea.MeshInstance.ConvertBy(root),
      activeMaterial = rootInstance.GetActiveMaterial(0),
      SpatialMaterial = Gaea.SpatialMaterial.ConvertBy(activeMaterial);

    SpatialMaterial.EmissionEnabled = true;

    SpatialMaterial.Emission = new Gaea.Color(
      color[0] / 255,
      color[1] / 255,
      color[2] / 255,
      color[3]
    );

    SpatialMaterial.EmissionEnergy = emissionEnergy;

    SpatialMaterial.EmissionOperator = emissionOperator;

    elementArr.forEach((item) => {
      var v = new Gaea.Vector3(0, waterLevel, 0);
      item.Root.TranslateObjectLocal(v);
    });
  };

  emissionClose = (element, floor) => {
    const root = element.Root.GetChild(floor),
      rootInstance = Gaea.MeshInstance.ConvertBy(root),
      activeMaterial = rootInstance.GetActiveMaterial(0),
      SpatialMaterial = Gaea.SpatialMaterial.ConvertBy(activeMaterial);

    SpatialMaterial.EmissionEnabled = false;
  };

  /**
   * @description: 在父模型中添加子模型
   * @param {Object} element 父模型
   * @param {String} gltfObj.name 模型的名称
   * @param {String} gltfObj.url 模型的请求位置
   * @param {Object} gltfObj.position 模型的位置
   * @param {Object} gltfObj.size 模型的大小
   * @param {Function} gltfObj.clickCallBack 模型点击事件回调
   * @return {*}
   */
  addChildrenGltf = (element, gltfObj) => {
    const {
      name,
      url,
      position,
      rotate,
      size,
      show,
      initFun = () => { },
      clickCallBack = () => { },
    } = gltfObj;

    return new Promise((res, rej) => {
      const childrenItemElement = new Gaea.GLTFElement();

      // 设置模型是否自动加到球上
      childrenItemElement.AddToWorld = false;

      // 模型请求地址
      childrenItemElement.ResourceName = url;

      // 模型旋转
      childrenItemElement.LocalRotation = new Gaea.Vector3(
        rotate[0],
        rotate[1],
        rotate[2]
      );

      // 模型缩放
      childrenItemElement.Scale = new Gaea.Vector3(size[0], size[1], size[2]);

      //Gaea.World.Instance.RenderableObjectList.AddLast(childrenItemElement);

      element.AddChild(childrenItemElement, false);
      childrenItemElement.OnModelInit(() => {
        // 初始方法
        initFun();
        // 是否自动往球上加
        //Gaea.World.Instance.RemoveChild(childrenItemElement.Root);

        // 模型命名
        childrenItemElement.Name = name;
        // 模型显隐
        childrenItemElement.Visible = show;
        // 模型定位
        childrenItemElement.Translation = new Gaea.Vector3(
          position.x,
          position.y,
          position.z
        );
      });

      res(childrenItemElement);
    });
  };

  /**
   * @description: 在父模型中添加子模型
   * @param {Object} element 父模型
   * @param {String} gltfObj.name 模型的名称
   * @param {String} gltfObj.url 模型的请求位置
   * @param {Object} gltfObj.position 模型的位置
   * @param {Object} gltfObj.size 模型的大小
   * @param {Function} gltfObj.clickCallBack 模型点击事件回调
   * @return {*}
   */
  addChildrenBillboard = async (element, billboardObj) => {
    const root = element.Root.GetChild(0),
      rootInstance = Gaea.MeshInstance.ConvertBy(root),
      meshInstance = rootInstance.GetMesh();

    const {
      position,
      size,
      rotate,
      paramsBillboardMode = 0,
      flagsNoDepthTest = false,

      name,
      show,
      materialData,
      clickCallBack = () => { },
    } = billboardObj;

    let modelElement = new Gaea.ModelElement();

    modelElement.Mesh = meshInstance;

    // 面片名称
    modelElement.Name = name;

    // 面片位置

    // 面片旋转角度
    modelElement.LocalRotation = new Gaea.Vector3(
      rotate[0],
      rotate[1],
      rotate[2]
    );

    // 面片大小控制
    // modelElement.Scale = new Gaea.Vector3(size[0], size[1], size[2]);

    // 所有材质集合
    let materialArr = [];

    for (let i = 0; i < materialData.length; i++) {
      const item = materialData[i];
      let resourceInstanceID = "";

      // 加载图片
      if (item.imageType === "png") {
        let backgroundName = `${uuid() + item.imageUrl}`;

        await this.LoadResourceFromUrl(
          `${window.location.origin}/static/billBoardImage/${item.imageUrl}`,
          backgroundName
        );

        // 加载图片数据
        resourceInstanceID =
          Gaea.GaeaResourceLoader.Instance.GetResource(backgroundName);
      } else if (item.imageType === "base64") {
        // 获取base64数据
        let base64 = item.base64;

        // 加载base64数据
        resourceInstanceID =
          Gaea.GaeaResourceLoader.Instance.LoadResourceFromBase64(
            base64.replace(/^data:image\/\w+;base64,/, ""),
            uuid() + ".png"
          ).toString();
      }
      const texture = new Gaea.ImageTexture(false),
        material = new Gaea.SpatialMaterial();

      texture.InstanceID = resourceInstanceID.toString();
      texture.SetFlags(7);

      material.FlagsUnshaded = true;
      material.AlbedoTexture = texture;

      // 底色透明
      material.FlagsTransparent = true;

      // 面片旋转是否可见
      material.ParamsBillboardMode = paramsBillboardMode;

      // 面片旋转后保持缩放
      material.ParamsBillboardKeepScale =
        paramsBillboardMode != 0 ? true : false;

      // 层级设置
      material.RenderPriority = item.zIndex || 99;

      // 设置下一层
      if (materialArr.length != 0) {
        materialArr[materialArr.length - 1].NextPass = material;
      }

      materialArr.push(material);
    }

    // 加面片
    // modelElement.Mesh.SurfaceSetMaterial(0, materialArr[0]);
    const ins = Gaea.MeshInstance.ConvertBy(modelElement.Root.GetChild(0));
    ins.MaterialOverride = materialArr[0];
    ins.ScaleObjectLocal(new Gaea.Vector3(1000, 1000, 1000));

    Gaea.World.Instance.RenderableObjectList.AddLast(modelElement);

    modelElement.Root.TranslateObjectLocal(
      new Gaea.Vector3(position.x, position.y, position.z)
    );

    modelElement.OnModelInit(() => {
      // 模型点击
      modelElement.EnablePick = true;

      // 是否自动往球上加
      Gaea.World.Instance.RemoveChild(modelElement.Root);
      rootInstance.AddChild(modelElement.Root, false);

      console.log(modelElement);

      console.log(rootInstance);

      // 面片命名
      // modelElement.Root.Name = name;

      // 面片定位
      // modelElement.Root.Translation = new Gaea.Vector3(
      //   position.x,
      //   position.y,
      //   position.z
      // );

      // 面片点击触发事件
      modelElement.OnHitElement((pos) => {
        console.log(name);

        // 点击回调函数
        clickCallBack();
      });
    });
  };

  /**
   * @description: 在模型的面片材质上添加额外材质
   * @param {Object} element 模型
   * @param {String} name 面片名称
   * @param {Number} floor 模型中的面片层
   * @param {Function} getBase64Function 获取base64的方法
   * @return {*}
   */
  addBillBoardByGltfFloor = async (
    element,
    { name, floor, getBase64Function }
  ) => {
    const floorMesh = element.Root.GetChild(floor),
      meshInstance = new Gaea.MeshInstance(),
      base64 = await getBase64Function();

    meshInstance.InstanceID = floorMesh.InstanceID;
    const material = meshInstance.Mesh.SurfaceGetMaterial(0);

    // 加载echarts
    let nextPassBase64 =
      Gaea.GaeaResourceLoader.Instance.LoadResourceFromBase64(
        base64.replace(/^data:image\/\w+;base64,/, ""),
        uuid() + ".png"
      );
    let nextPassTexture = new Gaea.Texture(false);
    nextPassTexture.InstanceID = nextPassBase64.toString();

    if (material.NextPass.InstanceID !== null) {
      let mtl = Gaea.SpatialMaterial.ConvertBy(material.NextPass);
      mtl.AlbedoTexture = nextPassTexture;
    } else {
      let nextPassspatialMaterial = new Gaea.SpatialMaterial();
      nextPassspatialMaterial.FlagsUnshaded = true;
      nextPassspatialMaterial.AlbedoTexture = nextPassTexture;
      nextPassspatialMaterial.FlagsTransparent = true;
      nextPassspatialMaterial.RenderPriority = 99;
      material.NextPass = nextPassspatialMaterial;
    }
  };

  /**
   * @description: 模型相对定位
   * @param {Object} element 模型
   * @param {String} name 面片名称
   * @param {Number} scale 缩放大小
   * @param {Object} position 面片位置
   * @param {String} imageUrl 面片图片路径
   * @param {Number} floor 面片绑定的层级
   * @param {Function} clickCallBack 模型点击事件回调
   * @return {*}
   */
  addBillBoardByGltfFloorMesh = async (
    element,
    { name, imageUrl, scale, floor, position, clickCallBack = () => { } }
  ) => {
    const gltf = element;

    const mesh = gltf.Root;

    const screen = mesh.GetChild(floor);

    const screen_instance = Gaea.MeshInstance.ConvertBy(screen);
    // 获取屏幕的模型
    const screen_mesh = screen_instance.GetMesh();

    const model = new Gaea.ModelElement();
    console.log("屏幕模型:", screen_instance);

    model.Mesh = screen_mesh;

    model.Name = name;
    // 将面片加到复制的模型中
    Gaea.World.Instance.RenderableObjectList.AddLast(model);

    model.OnModelInit(() => {
      model.EnablePick = true;
      model.OnHitElement((p) => {
        clickCallBack();
      });

      /**临时使用 */
      Gaea.World.Instance.RemoveChild(model.Root);
      screen_instance.AddChild(model.Root, false);
      /**临时使用 */
    });

    const { x, y, z } = position;

    // 加载图片
    let backgroundName = `${uuid() + imageUrl}`;

    await this.LoadResourceFromUrl(
      `${window.location.origin}/static/billBoardImage/${imageUrl}`,
      backgroundName
    );
    let resourceInstanceID = "";

    // 加载图片数据
    resourceInstanceID =
      Gaea.GaeaResourceLoader.Instance.GetResource(backgroundName);

    const texture = new Gaea.Texture(false),
      material = new Gaea.SpatialMaterial();

    texture.InstanceID = resourceInstanceID;

    material.FlagsUnshaded = true;
    material.AlbedoTexture = texture;

    // 底色透明
    material.FlagsTransparent = true;

    const ins = Gaea.MeshInstance.ConvertBy(model.Root.GetChild(0));
    ins.MaterialOverride = material;

    model.Root.TranslateObjectLocal(new Gaea.Vector3(x, y, z));
    ins.ScaleObjectLocal(new Gaea.Vector3(scale, scale, scale));
  };

  /**
   * @param {Number} meshID 模型的块id
   */
  //移除模型
  removeGltf = (meshID) => {
    Gaea.World.Instance.RemoveChild(meshID);
  };

  /**
   * @description: 新增标记点
   * @param {*} jsonUrl 标记点url
   * @param {*} IconStyle icon样式
   * @param {*} FontStyle 文字样式
   * @param {*} name 标记点名称
   * @param {*} callback 回调函数
   * @return {*}
   */
  AddSymbol = async (
    { jsonUrl, IconStyle, FontStyle, name },
    callback = () => { }
  ) => {
    let fileName = getFlieName(jsonUrl);
    await this.LoadResourceFromUrl(jsonUrl, fileName);
    // await LoadResourceAsync(jsonUrl);

    var ws = Gaea.WorkspaceFactory.OpenFromFile(`user://resources/${fileName}`);
    var fclass = ws.OpenFeatureClass(false, false);

    var Symbol = new Gaea.CompositeSymbol();

    var Field = FontStyle?.Field;
    //配置文字样式
    if (Field) {
      var textSymbol = new Gaea.TextMarkSymbol();

      textSymbol.HeightOffset = FontStyle?.HeightOffset || 30;
      textSymbol.MarkSize = FontStyle?.FontSize || 20;
      textSymbol.Field = FontStyle?.Field || "Name";
      textSymbol.Fontgap = 0.5;
      if (FontStyle?.FontColor?.length > 0) {
        textSymbol.FontColor = new Gaea.Color(
          FontStyle.FontColor[0] / 255,
          FontStyle.FontColor[1] / 255,
          FontStyle.FontColor[2] / 255,
          1
        );
        textSymbol.OutLineColor = new Gaea.Color(
          FontStyle.FontoOutLineColor[0] / 255,
          FontStyle.FontoOutLineColor[1] / 255,
          FontStyle.FontoOutLineColor[2] / 255,
          1
        );
      }
      textSymbol.Anchor = FontStyle?.FontAnchor
        ? Gaea.AnchorType[FontStyle.FontAnchor]
        : Gaea.AnchorType.Right;
      Symbol.AddSymbol(textSymbol);
    }

    // 配置icon样式
    var iconurl = IconStyle?.IconUrl;
    if (iconurl) {
      var iconSymbol = new Gaea.IconMarkSymbol();
      iconSymbol.HeightOffset = IconStyle?.HeightOffset || 30;
      let iconName = getFlieName(iconurl);
      await this.LoadResourceFromUrl(iconurl, iconName);
      iconSymbol.MarkSize = IconStyle?.IconSize || 20;
      var tex_id = Gaea.GaeaResourceLoader.Instance.GetResource(
        getFlieName(iconurl)
      );
      var tex = new Gaea.Texture(false);
      tex.InstanceID = tex_id + "";
      iconSymbol.Icon = tex;
      Symbol.AddSymbol(iconSymbol);
    }

    var flayer = new Gaea.FeatureLayer();
    var FeatureRenderer = new Gaea.SimpleFeatureRenderer();
    FeatureRenderer.Symbol = Symbol;

    flayer.FeatureClass = fclass;
    flayer.FeatureRenderer = FeatureRenderer;
    flayer.visibleRange = new Gaea.Vector2(0, 1000000000);

    console.log(flayer);

    Gaea.World.Instance.RenderableObjectList.AddLast(flayer);

    return flayer;
  };

  /**
   * @description: 切换空间实体显隐
   * @param {*} InstanceID 需要切换的id
   * @param {*} showType 显示/隐藏 状态
   * @return {*}
   */
  showTypeChange = (InstanceID, showType) => {
    // 通过new新的spatial操作显隐
    const sp = new Gaea.Spatial(false);
    sp.InstanceID = InstanceID;
    sp.Visible = showType;
  };

  //添加res模型
  AddRes = async (resObj, callback = () => { }) => {
    let { url, position, size } = resObj;
    size = size || 1;
    let resName = getFlieName(url);
    var ok = await this.LoadResourceFromUrl(url, resName);
    // if (!ok) {
    //     closeMsg.close()
    //     window.vue.$message(
    //         {
    //             type: 'warning',
    //             // offset: 80,
    //             message: '暂无该模型'
    //         }
    //     )
    //     return
    // }
    if (!ok) return alert("模型加载失败");
    var mesh_id = Gaea.GaeaResourceLoader.Instance.GetResource(resName);
    var aa = new Gaea.ArrayMesh(false);
    aa.InstanceID = mesh_id + "";
    var ins = new Gaea.MeshInstance();
    ins.Mesh = aa;
    Gaea.World.Instance.AddChild(ins, true);
    var rotate = Gaea.GaeaMath.RotationFromCartesianToSpherical(
      position.x,
      position.y
    ).GetEuler();
    var space = Gaea.GaeaMath.SphericalToCartesianDeg1(
      position.x,
      position.y,
      Gaea.World.Instance.WorldRadius + position.z
    );
    // ins.Layers = 2
    ins.Translation = space;
    ins.Rotation = rotate;

    ins.Scale = new Gaea.Vector3(size, size, size);
    callback(ins);
    window.vue.$message({
      type: "success",
      // offset: 80,
      message: "模型加载成功",
    });
    var camera = Gaea.World.Instance.DefaultCamera;
    camera.SetPosition2(position.x, position.y, 0, 3000, 0, 0);
  };
  //加载res整个模型
  AddResArr = async (resUrlObj, callback) => {
    let { urlArr, position, size } = resUrlObj;
    size = size || 1;
    let resArr = [];

    // Gaea.World.Instance.UseEscapeUri = true;
    for (let i = 0; i < urlArr.length; i++) {
      let item = urlArr[i];
      if (item.length === 1) {
        let url = item[0];
        let resName = getFlieName(url);
        this.LoadResourceFromUrl(url, resName)
          .then((ok) => {
            if (!ok) return window.vue.$message.error(`${resName}模型加载失败`);

            var mesh_id = Gaea.GaeaResourceLoader.Instance.GetResource(resName);
            var aa = new Gaea.ArrayMesh(false);
            aa.InstanceID = mesh_id + "";
            var ins = new Gaea.MeshInstance();
            ins.Mesh = aa;
            Gaea.World.Instance.AddChild(ins, true);
            ins.Mesh.ResourceName = resName.substring(
              0,
              resName.lastIndexOf(".")
            );
            var rotate = Gaea.GaeaMath.RotationFromCartesianToSpherical(
              position.x,
              position.y + Number(0.00013)
            ).GetEuler();
            var space = Gaea.GaeaMath.SphericalToCartesianDeg1(
              position.x,
              position.y,
              Gaea.World.Instance.WorldRadius + position.z + 20
            );

            ins.Layers = 2;
            ins.Translation = space;
            ins.Rotation = rotate;
            ins.Scale = new Gaea.Vector3(size, size, size);
            resArr.push(ins);
            if (resArr.length === urlArr.length) {
              callback(resArr);
            }
          })
          .catch((error) => { });
      }

      if (item.length > 1) {
        let mtlList = [];
        for (let index = item.length - 1; index >= 0; index--) {
          const element = item[index];
          if (index > 0) {
            let resName = getFlieName(element);
            const ok = await this.LoadResourceFromUrl(element, resName);
            if (!ok) return window.vue.$message.error(`${resName}模型加载失败`);
            var mesh_id = Gaea.GaeaResourceLoader.Instance.GetResource(resName);
            var mtl = new Gaea.ShaderMaterial(false);
            mtl.InstanceID = mesh_id + "";
            mtlList.push(mtl);
          } else {
            let resName = getFlieName(element);
            const ok = await this.LoadResourceFromUrl(element, resName);
            if (!ok) return window.vue.$message.error(`${resName}模型加载失败`);

            var mesh_id = Gaea.GaeaResourceLoader.Instance.GetResource(resName);
            var aa = new Gaea.ArrayMesh(false);
            aa.InstanceID = mesh_id + "";
            var ins = new Gaea.MeshInstance();
            ins.Mesh = aa;

            // var mtl=new Gaea.ShaderMaterial(false)

            // ins.SetSurfaceMaterial(0, mtl)
            Gaea.World.Instance.AddChild(ins, true);
            ins.Mesh.ResourceName = resName.substring(
              0,
              resName.lastIndexOf(".")
            );
            var rotate = Gaea.GaeaMath.RotationFromCartesianToSpherical(
              position.x,
              position.y + Number(0.00013)
            ).GetEuler();
            var space = Gaea.GaeaMath.SphericalToCartesianDeg1(
              position.x,
              position.y,
              Gaea.World.Instance.WorldRadius + position.z + 20
            );
            ins.Layers = 2;
            ins.Translation = space;
            ins.Rotation = rotate;
            ins.Scale = new Gaea.Vector3(size, size, size);
            // for (let i = 0; i < mtlList.length; i++) {
            //     const mtl = mtlList[i];
            //     ins.SetSurfaceMaterial(0, mtl)
            // }
            var mtl = new Gaea.SpatialMaterial();
            mtl.AlbedoColor = new Gaea.Color(255, 0, 0, 1);
            var lst = [];
            for (let i = 0; i < ins.GetSurfaceMaterialCount(); i++) {
              // lst.push(ins.GetSurfaceMaterial(i))
              ins.SetSurfaceMaterial(i, mtl);
            }

            // ins.SetSurfaceMaterial(0, mtlList[0])
            resArr.push(ins);
            if (resArr.length === urlArr.length) {
              callback(resArr);
            }
          }
        }
      }
      // let url = urlArr[i];

      // var ok = await this.LoadResourceFromUrl(url, resName);
    }
  };

  changeMtl = (ins, colorArr) => {
    var mtl = new Gaea.SpatialMaterial();
    mtl.AlbedoColor = new Gaea.Color(
      colorArr[0],
      colorArr[1],
      colorArr[2],
      colorArr[3] ? colorArr[3] : 1
    );
    mtl.FlagsTransparent = true;
    ins.SetSurfaceMaterial(0, mtl);
    // for (let i = 0; i < ins.GetSurfaceMaterialCount(); i++) {
    //     // lst.push(ins.GetSurfaceMaterial(i))
    // ins.SetSurfaceMaterial(i, mtl)
    // }
  };
  //移除res模型
  RemoveRes = (meshID) => {
    console.warn("移除res模型");
    Gaea.World.Instance.RemoveChild(meshID);
  };
  //模型点击是否开启
  changeModelClick = (callback) => {
    Gaea.QueryServer.Instance.Enable = true;

    Gaea.QueryServer.Instance.OnGeometryHit((id, pos) => {
      var ins = new Gaea.MeshInstance(false);
      ins.InstanceID = id + "";
      // var mtl = new Gaea.SpatialMaterial();
      // mtl.AlbedoColor = Gaea.Colors.Red;
      // Gaea.QueryServer.Instance.CurrentSelected = ins;
      // ins.MaterialOverride = mtl; // ins.Mesh.SurfaceSetMaterial(0, mtl); //ins.Mesh.ResourceName
      const clickName = ins.Mesh.ResourceName;
      //返回模型的名称
      // callback(clickName);
    });

    return;
  };

  // 模型点击
  modelClick() {
    alert("开启模型点击");
    Gaea.QueryServer.Instance.Enable = true;
    Gaea.QueryServer.Instance.OnGeometryHit((id, pos) => {
      var ins = new Gaea.MeshInstance(false);
      ins.InstanceID = id + "";
      var mtl = new Gaea.SpatialMaterial();
      mtl.AlbedoColor = Gaea.Colors.Red;
      Gaea.QueryServer.Instance.CurrentSelected = ins;
      ins.MaterialOverride = mtl;

      var hitpoint = new Gaea.Vector3(pos.x, pos.y, pos.z);
      var latlonalt = Gaea.GaeaMath.CartesianToSphericalDeg(hitpoint);
    });
    new Gaea.CompositeSymbol();
  }

  updateSunPower = (powerLevel) => {
    // 光照强度
    //Gaea.Sun.Instance.Power = powerLevel;
  };
  /**
   * @param {String} date 日照时间
   */
  //更新日照时间
  updateDate = (date = new Date().Format("yyyy-MM-dd HH:mm:ss")) => {
    // let time = 0;
    // setInterval(() => {
    //   Gaea.Sun.Instance.TimeString = timestr;
    //   var node = Gaea.World.Instance.GetNode("Sun");

    //   var sun = Gaea.Sun.ConvertBy(node);
    //   sun.TimeString = `2023-08-12 ${time}:00:00`;;

    //   time++;
    //   if (time >= 24) {
    //     time = 0;
    //   }

    //   console.log(timestr);
    // }, 1000);

    // 设置当前时间
    // Gaea.Sun.Instance.TimeString = "2023-08-12 16:00:00";
    // Gaea.Sun.Instance.TimeString = "2023-09-14 2:00:00";
    // 时间变化速率
    // Gaea.Sun.Instance.TimeScale = 0;
    // 光照强度
    this.updateSunPower(1);

    // // 天空亮度
    // Gaea.World.Instance.WorldEnvironment.Environment.BackgroundEnergy = 0.4;
    // // 环境光
    // Gaea.World.Instance.WorldEnvironment.Environment.AmbientLightColor =
    //   Gaea.Colors.White;
    // // 环境光强度
    // Gaea.World.Instance.WorldEnvironment.Environment.AmbientLightEnergy = 0.5;
    // // 环境光天空融合系数
    // Gaea.World.Instance.WorldEnvironment.Environment.AmbientLightSkyContribution = 0.5;
    // // 打开辉光效果
    // Gaea.World.Instance.WorldEnvironment.Environment.GlowEnabled = true;

    // // 启动亮度对比度饱和度
    // Gaea.World.Instance.WorldEnvironment.Environment.AdjustmentEnabled = true;
    // Gaea.World.Instance.WorldEnvironment.Environment.AdjustmentBrightness = 0.8; //亮度
    // Gaea.World.Instance.WorldEnvironment.Environment.AdjustmentContrast = 1.4; //对比度
    // Gaea.World.Instance.WorldEnvironment.Environment.AdjustmentSaturation = 1.2; //饱和度
  };

  drawUtil = (type) => {
    if (type == "line") {
      var linetool = new Gaea.DrawPolygonTool();
      Gaea.World.Instance.SetCurrentTool(linetool);
    }
    if (type == "point") {
      var linetool = new Gaea.DrawPointTool();
      Gaea.World.Instance.SetCurrentTool(linetool);
    }
    if (type == "current") {
      var linetool = new Gaea.DrawPolygonTool();
      Gaea.World.Instance.SetCurrentTool(linetool);
    }
  };
  /**
   * @param {String} FileInfo.url json地址
   * @param {String} FileInfo.name 别名
   * @param {String} FileInfo.fields 使用字段
   * @param {number} FileInfo.min 最小可视范围
   * @param {number} FileInfo.max 最大可视范围
   */
  loadPoint = async ({
    url,
    name,
    fields,
    max,
    min,
    FontStyle,
    IconStyle,
    BackColorStyle,
  }) => {
    //设置数据组名、显示字段名、geojson数据是否翻转
    //添加文本
    var json_str = await fetch(url);
    var json = await json_str.text();
    var group = Gaea.ScreenIconManager.Instance.AddScreenIconsByGeoJson(
      name,
      fields,
      json,
      true
    );
    //可视范围
    group.visibleRange = new Gaea.Vector2(min, max);
    //添加到球上去
    Gaea.ScreenIconManager.Instance.Draw();
    this.chagePointStyle(group, FontStyle, IconStyle, BackColorStyle, name);
  };

  /**
   * @param {String} fontName 标注的名称
   * @param {String} ttfUrl 文字请求样式
   * @param {String} ttf 文字样式
   * @param {String} imageUrl 图片请求地址
   * @param {number} fontSize 文字大小
   * @param {number} imageSize 图片大小
   * @param {String} backColor 背景图片颜色
   * @param {String} FontAnchor 文字对齐方式
   * @param {String} MarkAnchor 图标对齐方式
   * @param {String} BgAnchor 背景对齐方式
   */
  chagePointStyle = async (
    group,
    FontStyle,
    IconStyle,
    BackColorStyle,
    name
  ) => {
    //设置样式
    const defaultFontStyle = {
      ttf: null, //文字样式
      ttfUrl: null, //文字默认路径
      fontSize: 30, //文字大小
      FontAnchor: "None", //文字的对齐方式
    };
    const defaultIconStyle = {
      iconUrl: null, //图片请求路径
      iconSize: 30, //图片大小
      MarkAnchor: "None", //图片对齐方式
    };
    const defaultBackColorStyle = {
      backColor: "none", //背景颜色
      BgAnchor: "None", //背景对齐方式
    };
    FontStyle = FontStyle || {};
    IconStyle = IconStyle || {};
    BackColorStyle = BackColorStyle || {};
    const chageFontStyle = { ...defaultFontStyle, ...FontStyle };
    const chageIconStyle = { ...defaultIconStyle, ...IconStyle };
    const chageBackColorStyle = { ...defaultBackColorStyle, ...BackColorStyle };
    //    设置文字
    const { ttf, ttfUrl, fontSize, FontAnchor, FontColor } = chageFontStyle;
    const { iconUrl, iconSize, MarkAnchor } = chageIconStyle;
    const { backColor, BgAnchor } = chageBackColorStyle;
    if (ttf) {
      group.ChangeFontStyle(ttf);
    }
    if (ttfUrl) {
      const ttfName = getFlieName(ttfUrl);
      await this.LoadResourceFromUrl(ttfUrl, ttfName);
      group.ChangeFontStyle(ttfName, getFlieName(ttfUrl));
    }

    //设置图片
    //图片路径
    if (iconUrl) {
      let iconName = getFlieName(iconUrl);
      let imageID = await this.LoadResourceFromUrl(iconUrl, iconName);
      if (!imageID) {
        console.error(`${iconName}加载失败`);
      } else {
        group.ChangeMarkStyle("user://resources/" + iconName);
      }
    }
    //图标对齐方式
    if (MarkAnchor) {
      group.SetMarkAnchor(Gaea.AnchorType[MarkAnchor]);
      // group.currentMarkStyle.MarkOffset = 0;
    }
    //文字对齐方式
    if (FontAnchor) {
      group.SetFontAnchor(Gaea.AnchorType[FontAnchor]);
    }
    //设置文字颜色
    if (FontColor) {
      group.ChangeFontColor(Gaea.Color[FontColor]);
    }

    //文字和图片大小
    // if (fontSize || iconSize) {
    //     Gaea.ScreenIconManager.Instance.SetPolygonSize(fontSize, iconSize, name)
    // }
    //文字和图片大小
    if (fontSize) {
      group.SetTextSize(fontSize);
    }
    if (iconSize) {
      group.SetMarkSize(iconSize);
    }

    //设置背景色//添加背景色
    if (backColor != "none") {
      group.AddStyleItem(new Gaea.BackgroundItem()); //默认添加红色背景（当前暂无法更改颜色）
    }
    //背景的对齐方式
    if (BgAnchor) {
      group.SetBgAnchor(Gaea.AnchorType[BgAnchor]);
    }

    //设置图标大小和文字大小
    Gaea.ScreenIconManager.Instance.Draw();
    Gaea.ScreenIconManager.Instance.UpdateTexture();
    group.Update();
  };
  /**
   *  画高亮线
   *  @param {Array<number>} lines 画线的数组
   *  @param {number} height 线的高度
   *  @param {string} color 线的颜色
   *  @param {string} weight 线的粗细
   */
  drawHighlightLine = ({ lines, height, color, weight }) => {
    height = height || 30;
    color = color || "Back";
    weight = weight || 5;
    let v3Lines = lines.map((i) => {
      return new Gaea.Vector3(
        i[0],
        i[1],
        Gaea.World.Instance.WorldRadius + height
      );
    });
    var fp = v3Lines[0];
    var linepath = v3Lines.map((i) => {
      var r = Gaea.Vector3.op_Subtraction(
        Gaea.GaeaMath.SphericalToCartesianDeg(i),
        fp
      );
      return r;
    });
    var line = new Gaea.Line3d();
    line.Init(linepath, Gaea.Vector3[color], weight);
    line.Translation = fp;
    line.CreateLineSingleMesh();
    Gaea.Line3DManager.Instance.AddLine(line, Gaea.World.Instance);
  };
  //移除高亮线
  removeAllLines = () => {
    Gaea.Line3DManager.Instance.RemoveAllLines();
  };
  // 重置相机视角
  cameraRest = () => {
    var camera = Gaea.World.Instance.DefaultCamera;
    camera.Reset();
  };

  /**
   *  加载水面
   */
  async addWaterHandle({ waterLevel }) {
    //请求数据并保存至网页缓存
    var mesh_name = "waterdata.txt";
    var ok = await this.LoadResourceFromUrl(
      `${getServerAssets}/water/断面(2).txt`,
      mesh_name
    );

    if (!ok) return;
    //文件缓存路径，可从IndexDB查看
    var filePath = "/userfs/resources/waterdata.txt";

    let riverData = {
      mesh: "",
      river: "",
      element: "",
    };

    riverData.river = new Gaea.RiverGeometry();
    riverData.river.InitFromFile(filePath);
    // 流速
    riverData.river.FlowExaggerated = 0.1;
    // 图片纹理缩放
    riverData.river.UVScale = new Gaea.Vector3(17.0, 43.0, 1.0);
    // 河流颜色
    // riverData.river.FarColor = new Gaea.Vector3(75 / 255, 90 / 255, 75 / 255);

    // riverData.river.FarColor = new Gaea.Vector3(127 / 255, 111 / 255, 90 / 255);
    // 河流波浪粒子颜色
    // riverData.river.NearColor = new Gaea.Vector3(
    //   203 / 255,
    //   203 / 255,
    //   161 / 255
    // );
    riverData.river.NearColor = new Gaea.Vector3(
      127 / 255,
      111 / 255,
      75 / 255
    );
    riverData.river.Roughness = 0.2;

    riverData.mesh = riverData.river.GetRiverMesh();
    // 河流纹理图片
    // riverData.river.WaterNormal = "water2.jpg";
    // riverData.river.TransparencyClarity = 100;

    //加载至球上
    var point = riverData.river.OriginPoint;
    var rotate = Gaea.GaeaMath.RotationFromCartesianToSpherical(
      point.x,
      point.y
    ).GetEuler();

    var space = Gaea.GaeaMath.SphericalToCartesianDeg1(
      point.x,
      point.y,
      Gaea.World.Instance.WorldRadius + waterLevel
    );
    // ins.Layers = 2;
    riverData.mesh.Translation = space;
    riverData.mesh.Rotation = rotate;
    // var mtl = Gaea.ShaderMaterial.ConvertBy(riverData.mesh.MaterialOverride);
    // mtl.SetShaderParam("transparency_clarity", 100);
    // Gaea.World.Instance.AddChild(riverData.mesh, false);

    const element = new Gaea.GLTFElement(); // 是否使用绝对定位

    element.UseAbsoluteHeight = true; // 模型请求地址
    element.ResourceName = "gltf/水/river3.gltf";
    element.OnModelInit(() => {
      var mtlID = Gaea.GaeaResourceLoader.Instance.LoadLoaclResource(
        "res://assets/material/river.tres"
      );
      var mtl = Gaea.ShaderMaterial.ConvertBy(mtlID);

      element.mtl = mtl;
      let color = new Gaea.Color(63 / 255, 115 / 255, 113 / 255, 1);
      Gaea.World.Instance.SetMtlColor(riverData.element.mtl, "color_deep", color);
      Gaea.World.Instance.SetMtlFloat(riverData.element.mtl, "flow_speed", 0.02);

      element.GeographyPosition = new Gaea.Vector3(
        point.x,
        point.y,
        waterLevel
      );
      var num = element.Root.GetChildCount();
      for (var i = 0; i < num; i++) {
        var child = Gaea.MeshInstance.ConvertBy(element.Root.GetChild(i));
        var n = child.Mesh.GetSurfaceCount();
        for (var j = 0; j < n; j++) {
          child.Mesh.SurfaceSetMaterial(j, mtl);
        }
      }
    });

    Gaea.World.Instance.RenderableObjectList.AddLast(element);
    riverData.element = element;
    console.log(riverData);

    return riverData;

    //切换相机视角
    // var camera = Gaea.World.Instance.DefaultCamera;
    // camera.SetPosition2(point.x, point.y, 0, 1500, 0, 0);
  }

  /**
   *  更新水面高度
   *  @param {Object} riverData  pinia中河流对象
   *  @param {Number} waterLevel  水面高度
   */
  updateWaterLevel = (riverData, { waterLevel, flowSpeed, FarColor }) => {
    riverData.element.TranslateObjectLocal(new Gaea.Vector3(0, waterLevel, 0));

    // riverData.river.FlowExaggerated = flowSpeed;
    // riverData.river.NearColor = new Gaea.Vector3(
    //   0 / 255,
    //   0 / 255,
    //   0 / 255
    // );
    // 颜色
    let color = new Gaea.Color(
      FarColor[0] / 255,
      FarColor[1] / 255,
      FarColor[2] / 255,
      1
    );
    Gaea.World.Instance.SetMtlColor(riverData.element.mtl, "color_deep", color);
    // 流速
    Gaea.World.Instance.SetMtlFloat(riverData.element.mtl, "flow_speed", flowSpeed);
  };

  /**
   * 添加水淹没效果
   * @param {Array<Number>} poly  添加水面数据
   * @param {Number} height  添加水面高度
   * @param {Function} cb 返回水的id
   */
  addWater = async (poly, height, cb) => {
    let polyArr = [];
    //将二维坐标转换成笛卡尔坐标
    for (let i = 0; i < poly.length; i++) {
      const element = poly[i];
      // 地球半径
      let z = Gaea.World.Instance.WorldRadius - 10;
      var hitpoint = new Gaea.Vector3(element[1], element[0], z);
      polyArr.push(Gaea.GaeaMath.SphericalToCartesianDeg(hitpoint));
    }
    var water_mtl = Gaea.World.Instance.GetWaterMaterial(
      new Gaea.Color(0.1, 0.3, 0.5, 0.2)
    );
    let ins = Gaea.World.Instance.SimplePolygonGenerater(
      polyArr,
      false,
      true,
      false,
      height
    );
    for (let i = 0; i < ins.GetSurfaceMaterialCount(); i++) {
      ins.SetSurfaceMaterial(i, water_mtl);
    }
    Gaea.World.Instance.AddChild(ins, true);
    cb(ins);
  };
  //移除水淹没
  removeWater = (ins) => {
    Gaea.World.Instance.RemoveChild(ins);
  };
  /***
   * 添加图片标注
   * @param {String} image bs64图片地址||图片路径
   * @param {Array} extent 图片的位置
   * @return {Number} 图片的id
   */
  addImage = async ({ terrainList, extent }) => {
    Gaea.DecalManager.Instance.PixelToHeightMode =
      Gaea.PixelToHeightModeEnum.Proportion;
    Gaea.World.Instance.VerticalExaggeration = 1;

    const terrainName = `${uuid()}地形图.png`;
    await this.LoadResourceFromUrl(terrainList[0], terrainName);
    var item = new Gaea.DecalItem();
    item.ChangeRange(extent[0], extent[1], extent[2], extent[3]);

    item.BaseMapType = 1;

    item.BaseMapPath = `user://resources/${terrainName}`;

    Gaea.DecalManager.Instance.AddItem(item);

    this.dxyb_Arr.push(item);
  };

  /**
   * @description: 地形演变
   * @param {Array<String>} terrainArr  地形图
   * @param {Number} time 演变速度
   * @param {Number} verticalExaggeration 夸张倍数
   */
  addImageYb = async ({
    terrainList,
    extent,
    verticalExaggeration = 1,
    minMax,
    baseMapType,
  }) => {
    let item = new Gaea.DecalItem();
    let item2 = new Gaea.DecalItem();
    for (var i = 0; i < terrainList.length; i++) {
      const arr = terrainList[i].split("/");
      var name = arr[arr.length - 1];
      await this.LoadResourceFromUrl(terrainList[i], name);
    }
    await this.LoadResourceFromUrl(
      `${serveLocal}/static/billBoardImage/色带.png`,
      `色带.png`
    );
    Gaea.DecalManager.Instance.PixelToHeightMode =
      Gaea.PixelToHeightModeEnum.Absolute;
    Gaea.DecalManager.Instance.TerrainMapInterpolationValue = 1;
    Gaea.World.Instance.VerticalExaggeration = verticalExaggeration;
    item.ChangeRange(extent[0], extent[1], extent[2], extent[3]);
    item.BaseMapPath = "user://resources/色带.png";
    let itemArr = terrainList[0].split("/");
    item.TerrainMapPath = `user://resources/${itemArr[itemArr.length - 1]}`;
    item.TerrainMinMaxValue = new Gaea.Vector2(0, 232);
    item.TerrainInvalidValue = 0;
    item.BaseMapType = 2;
    Gaea.DecalManager.Instance.AddItem(item);

    item2.ChangeRange(extent[0], extent[1], extent[2], extent[3]);
    let item2Arr = terrainList[1].split("/");
    item2.TerrainMapPath = `user://resources/${item2Arr[item2Arr.length - 1]}`;
    item2.TerrainMinMaxValue = new Gaea.Vector2(0.06, 47);
    item2.TerrainInvalidValue = 0;
    item2.BaseMapType = 2;
    Gaea.DecalManager.Instance.AddItem(item2);
    // this.changeHeight(0, item, item2, terrainList);

    this.dxyb_Arr.push(item, item2);

    return this.dxyb_Arr;
  };
  terrainInterpolationValueChange = (value) => {
    Gaea.DecalManager.Instance.TerrainMapInterpolationValue = value;
  };
  //移除地形演变数据
  removeTerrainItem = () => {
    if (this.dxyb_Interval) {
      clearInterval(this.dxyb_Interval);
      this.dxyb_Interval = null;
    }

    this.dxyb_Arr.forEach((element) => {
      Gaea.DecalManager.Instance.RemoveItem(element);
    });

    this.dxyb_Arr = [];
  };
  // 画点
  drawPiont = () => {
    var linetool = new Gaea.DrawPointTool();
    Gaea.World.Instance.SetCurrentTool(linetool);
  };

  // 画线
  drawLine = () => {
    var linetool2 = new Gaea.DrawLineTool();
    linetool2.Color = new Gaea.Color(225, 0, 0, 1);
    Gaea.World.Instance.SetCurrentTool(linetool2);
    linetool2.OnMouseDoubleClick(() => { });
  };
  // 画面
  drawPolygon = () => {
    var linetool = new Gaea.DrawPolygonTool();
    // var c = new Gaea.Color()
    // c.r = 1
    // c.g = 1
    // c.b = 1
    // c.a = .1
    linetool.Color = new Gaea.Color(1, 1, 1, 0.4);
    Gaea.World.Instance.SetCurrentTool(linetool);
    linetool.OnMouseDoubleClick(() => { });
  };
  // 清除所有geojson
  clearDraw = () => {
    Gaea.World.Instance.SetCurrentTool(null);
  };
  //线测量
  addLineCl = () => {
    var tool = new Gaea.MeasureGroundContactDistanceTool();
    Gaea.World.Instance.SetCurrentTool(tool);
    tool.OverDraw += () => {
      Gaea.World.Instance.CurrentTool = null;
    };
  };
  //面测量
  addPolygonCl = () => {
    var tool = new Gaea.MeasureAreaPolygonTool();
    Gaea.World.Instance.SetCurrentTool(tool);
    tool.OverDraw += () => {
      Gaea.World.Instance.CurrentTool = null;
    };
  };
  //清除测量
  clearToolcl = () => {
    setTimeout(() => {
      var tool = new Gaea.MeasureAzimuthTool();
      Gaea.World.Instance.SetCurrentTool(tool);
      Gaea.World.Instance.SetCurrentTool(null);
      setTimeout(() => {
        var tool = new Gaea.MeasureAzimuthTool();
        Gaea.World.Instance.SetCurrentTool(tool);
        Gaea.World.Instance.SetCurrentTool(null);
      }, 100);
    }, 100);
  };
  //坐标转换
  GaeaMath = (x, y, z) => {
    var space = Gaea.GaeaMath.SphericalToCartesianDeg1(
      x,
      y,
      Gaea.World.Instance.WorldRadius + 20 + z
    ); //设置xyz
    return space;
  };
  //添加3dtils
  add3dtils = async (tilesObj) => {
    let { tilName, url, height } = tilesObj;
    const tdtile = new Gaea.Gaea3DTileset();
    tdtile.BaseUrl = url.slice(0, url.lastIndexOf("/") + 1);

    if (height) {
      tdtile.LocalOffset = new Gaea.Vector3(0, height, 0);
    }

    this.tdtile = tdtile;

    // 关闭跳层模式 直接加载最精细的层级
    tdtile.SkipLevelOfDetail = false;
    tdtile.EnableCache = true;
    tdtile.UseLight = true;
    tdtile.RecalculateNormals = true;
    tdtile.UseDefaultPbrParams = true;
    tdtile.MaximumMemoryUsage = 1024 * 3;
    await tdtile.InitTileSet(tilName, url);

    Gaea.World.Instance.RenderableObjectList.AddLast(tdtile);

    return tdtile;
  };
  remove3dtils = (tdtile) => {
    tdtile.Delete();
  };

  animate = (time) => {
    Tween.update();
    // requestAnimationFrame(this.animate);
  };

  /**
   * @description: 缓动动画
   * @param {Object} element  模型或者面片
   * @param {Object} Scale  需要变化的大小
   * @param {Object} Position  需要变化的位置
   * @param {Object} translation 模型相对位置的变化
   * @param {number} time  需要变化的时间
   * @param {Object} Door  开门关门
   * @param {Object} floor  门的层级
   */
  Tweentest = (
    element,
    Scale = {},
    Position = {},
    translation = {},
    time,
    Door = {},
    floor = 0
  ) => {
    if (Object.values(Scale).length) {
      const scale = {
        x: element.Scale.x,
        y: element.Scale.y,
        z: element.Scale.z,
      };
      const tweenScale = new Tween.Tween(scale, false) // Create a new tween that modifies 'coords'.
        .to(Scale, time) // Move to (300, 200) in 1 second.
        .easing(Tween.Easing.Quadratic.InOut) // Use an easing function to make the animation smooth.
        .onUpdate(() => {
          element.Scale = new Gaea.Vector3(scale.x, scale.y, scale.z);
        })
        .start()
        .onStart(() => {
          if (Scale.x !== 0.1) {
            element.Visible = true;
          }
        })
        .onComplete(() => {
          if (Scale.x === 0.1) {
            element.Visible = false;
          }
          Tween.remove(tweenScale);
        });
      Tween.add(tweenScale);
    }

    if (Object.values(Position).length) {
      const GeographyPosition = {
        x: element.GeographyPosition.x,
        y: element.GeographyPosition.y,
        z: element.GeographyPosition.z,
      };
      const tweenPosition = new Tween.Tween(GeographyPosition, false) // 需要变化的参数.
        .to(Position, time) // 最终变化的状态和时间.
        .easing(Tween.Easing.Quadratic.InOut) // 变化的速率曲线
        .onUpdate(() => {
          element.GeographyPosition = new Gaea.Vector3(
            GeographyPosition.x,
            GeographyPosition.y,
            GeographyPosition.z
          );
        })
        .start()
        .onComplete(() => {
          Tween.remove(tweenPosition);
        });
      Tween.add(tweenPosition);
    }

    if (Object.values(Door).length) {
      const doorMesh = element.Root.GetChild(floor),
        meshInstance = new Gaea.MeshInstance();
      meshInstance.InstanceID = doorMesh.InstanceID;
      const DoorAngle = {
        x: meshInstance.Rotation.x,
        y: meshInstance.Rotation.y,
        z: meshInstance.Rotation.z,
      };
      const tweenDoor = new Tween.Tween(DoorAngle, false) // 需要变化的参数.
        .to(Door, time) // 最终变化的状态和时间.
        .easing(Tween.Easing.Quadratic.InOut) // 变化的速率曲线
        .onUpdate(() => {
          meshInstance.Rotation = new Gaea.Vector3(
            DoorAngle.x,
            DoorAngle.y,
            DoorAngle.z
          );
        })
        .start()
        .onComplete(() => {
          Tween.remove(tweenDoor);
        });
      Tween.add(tweenDoor);
    }

    if (Object.values(translation).length) {
      let Translation;
      if (element.Root) {
        Translation = {
          x: element.Root.Translation.x,
          y: element.Root.Translation.y,
          z: element.Root.Translation.z,
        };
      } else {
        Translation = {
          x: element.Translation.x,
          y: element.Translation.y,
          z: element.Translation.z,
        };
      }

      const tweenTranslation = new Tween.Tween(Translation, false) // 需要变化的参数.
        .to(translation, time) // 最终变化的状态和时间.
        .easing(Tween.Easing.Quadratic.InOut) // 变化的速率曲线
        .onUpdate(() => {
          if (element.Root) {
            element.Root.Translation = new Gaea.Vector3(
              Translation.x,
              Translation.y,
              Translation.z
            );
          } else {
            element.Translation = new Gaea.Vector3(
              Translation.x,
              Translation.y,
              Translation.z
            );
          }
        })
        .start()
        .onComplete(() => {
          Tween.remove(tweenTranslation);
        });
      Tween.add(tweenTranslation);
    }

    // requestAnimationFrame(this.animate);
  };

  /**
   * @description: 雷达波动画
   * @param {Boolean} MaxMin  大波还是小波
   */
  animationUV = (element, flag = true) => {
    let nameArr = [];
    if (flag) {
      nameArr = ["雷达波01", "雷达波02", "雷达波03", "雷达波04"];
    } else {
      nameArr = ["Cylinder001"];
    }
    nameArr.forEach((item) => {
      var ins = element.GetMeshInstanceByName(item);
      var tt = ins.Mesh.SurfaceGetMaterial(0);
      var mtl = Gaea.SpatialMaterial.ConvertBy(tt);
      const Uv1Offset = { x: 0, y: 0, z: 0 };
      let obj = new Gaea.Vector3(Uv1Offset.x, Uv1Offset.y, Uv1Offset.z);
      const tween = new Tween.Tween(Uv1Offset, false) // Create a new tween that modifies 'coords'.
        .to({ x: 0, y: -10, z: 0 }, 10000) // Move to (300, 200) in 1 second. // .easing(Tween.Easing.Quadratic.InOut) // Use an easing function to make the animation smooth.
        .onUpdate(() => {
          obj.x = Uv1Offset.x;
          obj.y = Uv1Offset.y;
          obj.z = Uv1Offset.z;
          mtl.Uv1Offset = obj; // console.log(Uv1Offset);
        })
        .start()
        .repeat(Infinity); // Start the tween immediately.

      Tween.add(tween);
    });
    // requestAnimationFrame(this.animate);
  };

  // 手动画线
  drawLineTest = async () => {
    //请求线的贴图
    var tex = new Gaea.Texture(false);
    tex.InstanceID = 161648 + "";

    var tool = new Gaea.DrawLineTool(); //创建画线工具

    Gaea.World.Instance.SetCurrentTool(tool); //将当前工具挂载到根节点

    tool.OverDraw = () => {
      //拿到线的坐标（笛卡尔）
      var linedata = tool.GetLine();
      var line = []; //将生成的线的点坐标数据存储到line
      linedata.forEach((element) => {
        //笛卡尔转三维坐标
        var point = Gaea.GaeaMath.CartesianToSphericalDeg(element);
        line.push(point);
        console.log(line);
      }); //根据三维坐标生成Geometry
      var geometry = Gaea.Geometry.Create1(Gaea.GeometryType.Polyline, line); //创建画线工具

      var Element = new Gaea.LineGeometryElement(); //设置线的样式
      var lineSymbol = new Gaea.LineSymbol(); // lineSymbol.LineStyle = 1;
      lineSymbol.LineWidth = 100.0; //线宽
      lineSymbol.LineColor = Gaea.Colors.Green; //颜色
      lineSymbol.HeightOffset = 50 + 10; //线的高度偏移
      lineSymbol.IsFlowing = true; //是否是流线
      lineSymbol.LineTexture = tex; //将请求的贴图赋给线的纹理
      Element.Symbol = lineSymbol; //线的样式和形状赋给Element
      Element.Shape = geometry;
      Element.EnablePick = false; //能否点击
      Element.EnableEdit = false; //能否编辑
      Gaea.World.Instance.RenderableObjectList.AddLast(Element); //将Elemnet加载到Wrold
      Gaea.World.Instance.SetCurrentTool(null); //清空当前根节点的工具
    };
  };

  // 面片uv动画
  quadmeshUVTest = async () => {
    //请求纹理
    await this.LoadResourceFromUrl(
      `${window.location.origin}/static/billBoardImage/箭头.png`,
      `箭头.png`
    );
    var id = Gaea.GaeaResourceLoader.Instance.GetResource("箭头.png");
    var tex = new Gaea.Texture(false);
    tex.InstanceID = id + "";
    var position = new Gaea.Vector3(30.293067, 112.251993, 40);
    var rotate = [0, 0, -37]; // var size = [1, 1, 1];
    let quadmesh = new Gaea.ModelElement();
    // 是否使用相对定位
    quadmesh.UseAbsoluteHeight = true;
    quadmesh.Name = "quad"; // 面片名称
    var mesh = new Gaea.QuadMesh();
    mesh.Size = new Gaea.Vector2(1700, 10); //面片长宽
    quadmesh.Mesh = mesh;

    var backgroundMtl = new Gaea.SpatialMaterial();
    backgroundMtl.FlagsUnshaded = true;
    backgroundMtl.FlagsTransparent = true; // 底色透明
    backgroundMtl.AlbedoTexture = tex; //设置材质纹理
    backgroundMtl.ParamsCullMode = 2; //显示双面 // 加面片
    backgroundMtl.RenderPriority = 99;
    quadmesh.Mesh.SurfaceSetMaterial(0, backgroundMtl); //设置mesh的材质 // 面片位置
    quadmesh.GeographyPosition = new Gaea.Vector3(
      position.x,
      position.y,
      position.z
    ); // 面片旋转角度
    quadmesh.LocalRotation = new Gaea.Vector3(rotate[0], rotate[1], rotate[2]);
    quadmesh.VisibleRange = new Gaea.Vector2(0, 1000000000); //可见范围
    quadmesh.Root.Visible = true;
    let tween;
    quadmesh.OnModelInit(() => {
      console.log(backgroundMtl);
      backgroundMtl.Uv1Scale = new Gaea.Vector3(50, 1, 1); //纹理缩放，可以控制某个方向纹理重复的次数 //纹理动画

      const Uv1Offset = { x: 0, y: 0, z: 0 };
      tween = new Tween.Tween(Uv1Offset, false) // Create a new tween that modifies 'coords'.
        .to({ x: -10, y: 0, z: 0 }, 5000) // Move to (300, 200) in 1 second. // .easing(window.TWEEN.Easing.Quadratic.InOut) // Use an easing function to make the animation smooth.
        .onUpdate(() => {
          backgroundMtl.Uv1Offset = new Gaea.Vector3(
            Uv1Offset.x,
            Uv1Offset.y,
            Uv1Offset.z
          ); // console.log(Uv1Offset);
        })
        .start()
        .repeat(Infinity); // Start the tween immediately.

      Tween.add(tween);
      // requestAnimationFrame(this.animate);
    });

    Gaea.World.Instance.RenderableObjectList.AddLast(quadmesh);

    return quadmesh;
    console.log("xxxxxxxxxxxxxxxxxxxxxxx8");
    Gaea.World.Instance.RenderableObjectList.AddLast(element);
  };

  /**
   * @description: 屏幕标记
   * @param {String} name  标记名称
   * @param {Boolean} show  标记是否可见
   * @param {String} url  标记背景图
   * @param {Object} position  标记位置
   * @param {Array} size  标记大小[宽,高]
   * @param {Array} offset  标记偏移[左,上]
   * @param {Array} text  标记内容文本
   * @param {Number} anchorType  点方位(详情见index.js => AnchorType)
   * @param {Array} visibleRange  标记可见范围[min,max]
   * @param {Boolean} DrawLine  是否带线
   * @param {Boolean} EnablePick  是否点击
   * @param {Function} clickCallBack  标记点击方法
   */
  addScreenIcon = async ({
    name,
    show,
    url = "",
    position,
    size = [],
    offset = [],
    text = [],
    anchorType,
    visibleRange = [],
    DrawLine = false,
    EnablePick = true,
    divId = "",
    clickCallBack = () => { },
    elementName = "",
  }) => {
    if (url) {
      await this.LoadResourceFromUrl(
        `${serveLocal}/static/billBoardImage/${url}`,
        url
      );
    }
    const screen = new Gaea.ScreenGUIElement();
    screen.UseAbsoluteHeight = true;
    // screen.EnableDepthTest = true;
    screen.Name = name;
    if (divId) {
      screen.DivID = divId;
      const dom = document.getElementById(screen.DivID);
      console.log(dom);
      const root = new Gaea.Control();
      root.MouseFilter = 2;
      root.RectSize = new Gaea.Vector2(dom.clientWidth, dom.clientHeight);
      screen.Root = root;
    } else {
      screen.Root = this.InitUI(text, size, url);
    }
    // 标签的地理坐标
    if (!elementName) {
      screen.GeographyPosition = new Gaea.Vector3(
        position.x,
        position.y,
        position.z
      );
    }
    // 标签默认位置为左上角
    screen.ScreenOffset = new Gaea.Vector2(offset[0], offset[1]); // 偏移位置
    screen.ConnectAnchor = anchorType; // 点方位

    // screen.LineWidth = screen.AnchorPointSize = 10; // 线条和圆点粗细
    // screen.LineColor = new Gaea.Color(0, 0, 0, 0); // 线条颜色
    // screen.AnchorPointColor = new Gaea.Color(0, 0, 0, 0); // 圆点颜色
    // 是否带线
    screen.DrawLine = DrawLine;
    // 是否显示
    screen.Visible = show;
    // 可见范围
    if (visibleRange.length) {
      screen.VisibleRange = new Gaea.Vector2(visibleRange[0], visibleRange[1]);
    }
    // 是否点击
    screen.EnablePick = EnablePick;
    screen.OnHitElement = () => {
      clickCallBack();
    };

    if (elementName) {
      let elementObj;
      const { element } = gltfList.value.find(
        (item) => item.name === elementName
      );
      if (elementName.includes("ADCP") && url === "RTU.png") {
        elementObj = element.GetMeshInstanceByName("屏幕");
      } else if (elementName.includes("测量船")) {
        elementObj = element.GetMeshInstanceByName("定位");
      } else {
        elementObj = element;
      }
      elementObj.AddChild(screen, false);
    } else {
      Gaea.World.Instance.RenderableObjectList.AddLast(screen);
    }
    return screen;
  };

  /**
   * 初始化容器
   * @param {*} text
   */
  InitUI = (text = [], size, name) => {
    // 边距容器 MouseFilter = 2 为不捕捉鼠标
    const root = new Gaea.TextureRect(); //父容器
    // root.Visible = show;
    if (name) {
      var tex = new Gaea.Texture(false);
      tex.InstanceID =
        Gaea.GaeaResourceLoader.Instance.GetResource(name).toString();
      root.Texture = tex; // 图片名称

      root.Expand = true; // 开启图片缩放
      root.StretchMode = 6; // 缩放模式
    }
    // const root = new Gaea.Panel();
    root.MouseFilter = 2; // 鼠标移到标记上可以转动视角
    root.RectSize = new Gaea.Vector2(size[0], size[1]);
    // 居中布局容器
    // const center = new Gaea.CenterContainer()
    // center.MouseFilter = 2
    // center.AnchorRight = 1
    // center.AnchorBottom = 1
    // center.MarginRight = 0
    // center.MarginBottom = 0
    // center.MouseFilter = 2
    // 纵向排布容器
    const vbox = new Gaea.VBoxContainer();
    vbox.MouseFilter = 2;
    vbox.AnchorRight = 1;
    vbox.AnchorBottom = 1;
    vbox.MarginRight = 0;
    vbox.MarginBottom = 0;
    if (text && text.length) {
      text.forEach((element) => {
        const label = new Gaea.Label();
        label.Text = element.text;
        vbox.AddChild(label, false);
      });
    }
    // center.AddChild(vbox, false)
    root.AddChild(vbox, false);
    return root;
  };

  //加载默认资源
  InitFontTexture = (params) => {
    return new Promise((res, rej) => {
      var over = Gaea.TextTextureManager.Instance.OnInitFontTexture((ok) => {
        if (ok) {
          res();
        } else {
          rej();
        }
        Gaea.TextTextureManager.Instance.ClearEvent(over);
      });
      Gaea.TextTextureManager.Instance.InitFontTexture();
    });
  };
  /**
   * 加载文件资源
   * @param {string} url 路径
   * @param {string} name 文件名
   * @return {Promise}
   */
  // LoadResourceFromUrl = (url, name) => {
  //     // return new Promise((res, rej) => {
  //     //     var rfid = Gaea.GaeaResourceLoader.Instance.OnLoadResourceFromUrl((code, id) => {
  //     //         if (code == 200) {
  //     //             id = Gaea.GaeaResourceLoader.Instance.GetResource(name)
  //     //             res(id)
  //     //         } else {
  //     //             rej(id)
  //     //         }
  //     //         Gaea.GaeaResourceLoader.Instance.ClearEvent(rfid)
  //     //     })
  //     //     Gaea.GaeaResourceLoader.Instance.LoadResourceFromUrl(url, name)
  //     // })

  // }
  /**
   * 通过URL加载资源,并存储为Name
   * @param {string} url
   * @param {string} name
   */
  LoadResourceFromUrl = (url, name) => {
    return new Promise((res, rej) => {
      try {
        Gaea.GaeaResourceLoader.Instance.LoadResourceFromUrl1(
          url,
          name,
          (code, msg) => {
            if (code == 200) {
              res(true);
            } else {
              rej(false);
            }
          }
        );
      } catch (error) {
        rej(false);
      }

      // var rfid = Gaea.GaeaResourceLoader.Instance.OnLoadResourceFromUrl((code, id) => {
      //     if (code == 200) {
      //         res(id)
      //     } else {
      //         rej(id)
      //     }
      //     Gaea.GaeaResourceLoader.Instance.ClearEvent(rfid)
      // })
    });
  };

  /**
   * 绘制多边形
   * @param {string} type // 绘制方法
   * 1-PolygonGeometryElement 绘制
   * 2-CutPolygon 切割
   * 3-LineGeometryElement 选中线段(选中的线段变成绿色)
   * 4-Interpolation 插值(前提是有线段被选中)
   * 5-DeleteChoosePolygon 删除选中的多边形
   * 6-ClearAllData 清除所有绘制的多边形
   */
  drawPolygons = (type) => {
    let EditElement = new Gaea.RiverEditTool();

    switch (type) {
      case 1:
        let isTree = false;
        if (isTree == false) {
          Gaea.World.Instance.SetCurrentTool(EditElement);
          isTree = true;
        }
        EditElement.OperateItem =
          Gaea.RiverEditOperateOption.PolygonGeometryElement;
        break;
      case 2:
        EditElement.OperateItem = Gaea.RiverEditOperateOption.CutPolygon;
        break;
      case 3:
        EditElement.OperateItem =
          Gaea.RiverEditOperateOption.LineGeometryElement;
        break;
      case 4:
        EditElement.StepLength = 4;
        EditElement.StepProportion = 1;
        EditElement.OperateItem = Gaea.RiverEditOperateOption.Interpolation;
        break;
      case 5:
        EditElement.OperateItem =
          Gaea.RiverEditOperateOption.DeleteChoosePolygon;
        break;
      case 6:
        EditElement.OperateItem = Gaea.RiverEditOperateOption.ClearAllData;
        break;
    }
  };

  /**
   * 流场加载
   * @param {参数} 参数名
   */
  loadFlowField = () => {

    Gaea.GaeaResourceLoader.Instance.LoadResourceFromUrl1(
      `${window.location.origin}/static/flowfield/流场测试/UVW/UVW0.dat`,
      "aaa/UVW/UVW0.dat",
      () => {
        Gaea.GaeaResourceLoader.Instance.LoadResourceFromUrl1(
          `${window.location.origin}/static/flowfield/流场测试.xml`,
          "aaa.xml",
          () => {
            const path = "/userfs/resources/aaa.xml";
            var _mdProvider = new Gaea.MeteorDataProvider();
            _mdProvider.Initialize(path);
            var _streaml = new Gaea.StreamLineTD();
            _streaml.Initialize1("UVW", _mdProvider);
            //流线每隔几帧刷新一次，默认为5
            _streaml.GapFrame = 5;
            Gaea.World.Instance.AddChild(_streaml, false);
          }
        );
      }
    );

    Gaea.World.Instance.DefaultCamera.SetPosition2(29.76, 112.9, 0, 1500, 0, 0);
  };

  // 流场多测次加载
  Func2 = (i) => {
    if (!this._streaml) {
      this._streaml = new Gaea.StreamLineTD();
      this._streaml.GapFrame = 5;
      this._streaml.ColorEnd = new Gaea.Color(1, 0, 0, 1);
      this._streaml.ColorStart = new Gaea.Color(0, 0, 1, 1);
      // this._streaml.LineDensity = 1;
      this._streaml.LineDensity2 = 2;
      this._streaml.ModelHeight = 100;
      this._streaml.TimeNum = i;
      this._streaml.IsAddDatToLine = false;
      this._streaml.Initialize();
      Gaea.World.Instance.AddChild(this._streaml, false);
      // Gaea.World.Instance.DefaultCamera.SetPosition2(31.76, 120.268, 0, 1500, 0, 0)
    }
    var meshpath = `res://assets/flowField/FlowField${i}.res`;
    var res = Gaea.Mesh.ConvertBy(Gaea.GaeaResourceLoader.Instance.LoadLoaclResource(meshpath));
    this._streaml.MeshInstance.Mesh = res;
  }
}
