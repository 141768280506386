/*
 * @Descripttion:
 * @version:
 * @Author: xcb
 * @Date: 2023-03-06 09:21:00
 * @LastEditors: gzy 810461372@qq.com
 * @LastEditTime: 2023-06-07 17:36:41
 */
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/",
    redirect: "/home/largeDataScreen",
    children: [
      {
        path: "/home",
        redirect: "/home/largeDataScreen",
        component: () => import("@/views/home/home.vue"),
        children: [
          {
            path: "simulation",
            name: "simulation",
            component: () => import("@/views/simulation/index.vue"),
          },
          {
            path: "largeDataScreen",
            name: "largeDataScreen",
            component: () => import("@/views/largeDataScreen/index.vue"),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(""),
  routes,
});

router.beforeEach((to, from, next) => {
  let token = "";
  if (localStorage.getItem("userInfo")) {
    token = JSON.parse(localStorage.getItem("userInfo")).token || false;
  }
  if (token) {
    if (to.name === "login") {
      next("/");
    } else {
      next();
    }
  } else {
    if (to.name === "login") {
      next();
    } else {
      next("/login");
    }
  }
});

export default router;
