import { defineStore } from "pinia";

const gaeaData = defineStore("gaeaData", {
  state: () => ({
    // gaea球是否加载完成
    gaeaIsLoding: false,
    // 图层列表
    layersList: [],
    // 3dtil列表
    tilsList: [],
    // gltf列表
    gltfList: [],
    // 公告牌列表
    billBoardList: [],
    // 标注点列表
    symbolList: [],
    guiSymbolList: [],
    // 河流对象
    riverList: [],
    // 大屏侧边栏是否展示
    largeDataScreenShow: true,
    // 水文站vr视角是否进入
    hydrologicalVR: false,
    // 水文站鱼骨图是否展示
    hydrologicalFishbone: false,
    // 水文站室外鱼骨图是否展示
    hydrologicalOutdoorFishbone: false,
    // 走廊鱼骨图
    galleryFishbone: false,
    // 荆江重大水患年谱鱼骨图
    chronologyFishbone: false,
    // 室外视频弹窗
    outdoorVideoDialog: false,
    // 实时数据
    realTimeData: {
      s: 0, // 含沙
      v: 0, // 流速
      lives: 0, // 天气
      z: 0, // 水位
      r: 0, // 雨量
      t: 0, // 工况
    },
    // 面片弹窗
    billBoardDialogData: {
      show: false,
      type: "",
      dialogConfig: {},
    },
    // 报表分类
    reportClassification: "",
    // 滚轮力度
    rollerPower: 0,
    // 是否点击复位
    isReposition: false,
    // 是否展示大模型加载动画
    isBigGltfLoading: false,
    // 大模型加载动画进度条关闭
    isBigGltfProgress: true,

    // 预演框分类
    isPreview: "",
    // 无人机时间
    droneTime: 0,
    // 二维图表触发参数
    echartsOn: false,

    // 屏幕标记
    ScreenIconList: [],

    // 温度
    temperature: "",

    // 数据标记索引
    dataIndex: {
      adcpIndex: 0,
      flowIndex: 0,
      droneIndex: 0,
    },

    // 无人机样式分类
    droneStyle: "",

    // 预演详情按钮是否可操作
    isEdit: true,
  }),
  actions: {
    //gaea球加载完成
    changeGaeaLoding() {
      // this.gaeaIsLoding = true;
      this.gaeaIsLoding = false;
    },
    // 添加图层
    async addLayer(layerObj) {
      const layer = await APP.addLayer(layerObj);

      this.layersList.push({
        layer,
      });
    },
    // 添加3dtils
    async add3dtils(tilsObj) {
      const tils = await APP.add3dtils(tilsObj);

      this.tilsList.push({
        tilName: tilsObj.name,
        show: true,
        tils,
      });
    },
    // 添加gltf模型
    addGltf(gltfObj, cb) {
      return new Promise(async (res, rej) => {
        const gltf = await APP.AddGltf(gltfObj);

        this.gltfList.push({
          ...gltfObj,
          element: gltf,
        });

        res();
      });
    },
    // 添加面片
    async addBillBoard(billBoardObj) {
      const billBoard = await APP.addBillBoard(billBoardObj);

      this.billBoardList.push({
        ...billBoardObj,
        element: billBoard,
      });
    },

    // 添加屏幕标记
    async addScreenIcon(ScreenIconObj) {
      const ScreenIcon = await APP.addScreenIcon(ScreenIconObj);

      this.ScreenIconList.push({
        ...ScreenIconObj,
        element: ScreenIcon,
      });
    },
    /**
     * @description: 添加标注点
     * @return {*}
     */
    async addSymbol(iconData) {
      const symbol = APP.AddSymbol(iconData);

      this.symbolList.push({
        name: iconData.name,
        symbol,
      });
    },
    async addGuiSymbol(guiSymbolObj) {
      const guiSymbol = APP.addGuiSymbol(guiSymbolObj);

      this.guiSymbolList.push({
        ...guiSymbolObj,
        element: guiSymbol,
      });
    },

    /**
     * @description: 添加标注点
     * @param {*} riverOBj 河流对象
     * @return {*}
     */
    async addWaterHandle(riverOBj) {
      const river = await APP.addWaterHandle(riverOBj);

      this.riverList.push({
        ...riverOBj,
        ...river,
      });
      setTimeout(() => {
        this.gltfList.forEach((item) => {
          if (
            item.name === "测量船" ||
            item.name === "船坞" ||
            item.name === "测量船206"
          ) {
            // item.position.z = ((state.waterData.value - 29.5) * 16) / 15.5 + 29.5;
            item.position.z = Number(this.riverList[0].waterLevel) - 0.8;
            APP.updateElementPosition(item);
          } else if (
            item.name === "垂直ADCP1" ||
            item.name === "adcp1前vr定位" ||
            item.name === "垂直ADCP2" ||
            item.name === "adcp2前vr定位"
          ) {
            item.position.z = Number(this.riverList[0].waterLevel) + 0.3;
            APP.updateElementPosition(item);
          }
        });

        this.billBoardList.forEach((item) => {
          if (
            item.name === "实时流速" ||
            item.name === "断面" ||
            item.name === "实时流速1"
          ) {
            item.position.z = Number(this.riverList[0].waterLevel) + 15;
            APP.updateElementPosition(item);
          }
        });
      }, 15 * 1000);
    },

    /**
     * @description: 面片显示切换
     * @param {*} billBoardName 需要切换的面片名称
     * @return {*}
     */
    billBoardShowChange(billBoardName) {
      // 从当前面片数组中取出需要操作的面片
      const billBoardItem = this.billBoardList.find(
        (item) => item.name === billBoardName
      );

      // show属性取反
      billBoardItem.show = !billBoardItem.show;

      // 调用gaeaMethods方法 切换显示状态
      APP.showTypeChange(
        billBoardItem.billBoard.InstanceID,
        billBoardItem.show
      );
    },

    /**
     * @description: 弹窗展示
     * @param {*} show 是否展示
     * @param {*} type 弹窗类型
     * @param {*} dialogConfig 弹窗配置参数
     * @return {*}
     */
    billBoardDialogShowTypeChange(show, type = "", dialogConfig = {}) {
      this.billBoardDialogData = {
        show,
        type,
        dialogConfig,
      };
    },

    // 移除gltf模型
    removeGltf(gltfName) {
      let index = this.gltfList.findIndex((item) => {
        return item.gltfName === gltfName;
      });

      let gltfData = this.gltfList[index].gltfData;
      gltfData.forEach((item) => {
        APP.removeGltf(item);
      });
      this.tilsList.splice(index, 1);
    },
    // 移除公告牌
    removeBillBoard(billBoardName) {
      let index = this.billBoardList.findIndex((item) => {
        return item.billBoardName === billBoardName;
      });

      let billBoard = this.billBoardList[index].billBoardData;
      APP.removeBillBoard(billBoard);
      this.billBoardList.splice(index, 1);
    },
    //移除某个3dtils
    remove3dtils(tilName) {
      let index = this.tilsList.findIndex((item) => {
        return item.tilName === tilName;
      });

      let tils = this.tilsList[index].tilData;
      APP.remove3dtils(tils);
      this.layersList.splice(index, 1);
    },
    //移除图层
    removeLayer(layerName) {
      let index = this.layersList.findIndex((item) => {
        return item.Name === layerName;
      });

      this.layersList.splice(index, 1);
      APP.removeLayer(layerName);
    },
  },
});

export default gaeaData;
